@import '~scss/variables.scss';

.thumbnail {
    &__marker {
        position: absolute;
        top: 50%;
        z-index: 10;
        width: 0;
        transform: translate(-50%, -50%);
        cursor: pointer;
        transition: left linear 0.25s;

        &:hover {
            .thumbnail__modal-wrapper {
                opacity: 1;
            }
            .thumbnail__icon {
                &:after {
                    transform: scale(1.3);
                }
            }
        }

        &--active {
            .thumbnail__icon {
                svg {
                    position: absolute;
                    fill: $background-color-buttercup;
                }
                &:after {
                    background-color: rgba($background-color-buttercup, 0.1);
                    border: 1px solid rgba($background-color-buttercup, 0.5);
                    transform: scale(1.3);
                }
            }
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6px;
        height: 6px;
        margin-left: -3px;
        border-radius: 3px;
        svg {
            position: absolute;
            transition: fill linear 0.1s;
            fill: $background-color-lochmara;
        }

        &:after,
        &:before {
            position: absolute;
            content: '';
        }

        &:after {
            width: 24px;
            height: 24px;
            background-color: rgba($background-color-lochmara, 0.2);
            border: 1px solid rgba($background-color-lochmara, 0.5);
            border-radius: 12px;
            transition: transform linear 0.1s, background-color linear 0.1s, border-bottom-color linear 0.1s, border-top-color linear 0.1s,
                border-left-color linear 0.1s, border-right-color linear 0.1s;
            pointer-events: none;
        }

        &:before {
            width: 16px;
            height: 16px;
            border-radius: 8px;
        }
    }

    &__modal-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        box-sizing: content-box;
        width: 170px;

        border: 1px solid #33353d;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba($color-black, 0.5);
        transform: translate(-50%, -115%);
        opacity: 0;
        transition: opacity linear 0.25s, left linear 0.25s;
        pointer-events: none;
    }

    &__modal {
        position: relative;
        top: unset;
        left: unset;
        z-index: 1001;
        width: 100%;
        padding: 10px;
        background-color: $color-base-dark;
        transform: unset;

        .thumbnail__image {
            box-sizing: border-box;
            width: 100%;
        }

        &:after,
        &:before {
            position: absolute;
            top: 100%;
            left: 50%;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            transform: translateX(-50%);
            content: '';
        }

        /* outline triangle */
        &:after {
            z-index: 1;
            border-color: #33353d transparent transparent transparent;
            border-width: 11px;
        }

        /* triangle fill */
        &:before {
            z-index: 2;
            border-color: #0d0d14 transparent transparent transparent;
            border-width: 10px;
        }
    }
}
