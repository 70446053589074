@import '~scss/variables.scss';

.time-range {
    margin-left: 15px;

    color: #31343c;
    font-size: 12px;
    font-family: 'Roboto-Regular', arial;
    white-space: nowrap;

    &__elapsed-time {
        color: #a9adb7;
        font-family: 'Roboto-Regular', arial;
    }

    &__total-time {
        color: $text-color-santas-gray;
        font-family: 'Roboto-light', arial;
    }
}
