@import '../../../scss/variables.scss';

.player-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    box-sizing: content-box;
    max-width: 1280px;
    padding: 20px !important;
    transform: translate(-50%, -50%);

    .modal__title {
        font-size: 20px;
        + .modal__body {
            margin-top: 20px;
        }
    }

    video {
        width: 100%;
        height: 100%;
    }

    &__links {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;

        a {
            display: flex;
            align-items: center;
            color: $color-white;
            font-size: 14px;
            font-family: $font-light;

            &:hover {
                color: $color-white;
                text-decoration: none;
            }

            svg {
                width: 14px;
                height: 14px;
                margin-right: 5px;
                fill: $color-white;
            }
        }
    }
}

.edit-action-btn {
    position: absolute;
    top: 20px;
    left: 20px;
}
