@import '~scss/variables.scss';
$border-color: rgba(0, 27, 45, 0.1);
$button-border-color: rgba($background-color-osiogray, 0.2);
$very-light-blue: #f4f9fc;

.preview-schedule-modal {
    .modal {
        top: unset;
        bottom: 0;
        left: unset;
        max-width: 100%;
        max-height: 0vh;
        padding: 0;
        background: $color-white;
        transform: unset;
        transition: max-height 0.5s;
    }

    .overlay {
        transition: opacity 0.3s;
    }

    &--sneek .modal {
        max-height: 50vh;
    }

    &--full .modal {
        max-height: 100vh;
    }

    .modal__title,
    .modal__body {
        margin: 0;
        padding: 0;
    }

    &-enter {
        .modal {
            max-height: 0vh;
        }

        .overlay {
            opacity: 0.5;
        }
    }

    &-enter-active {
        .modal {
            max-height: 100vh; // use 50vh for sneek preview, not used for now
        }

        .overlay {
            opacity: 1;
        }
    }

    &-exit-active .overlay {
        opacity: 0;
    }
}
