@font-face {
    font-family: 'Roboto-Thin';
    src: url('../assets/fonts/Roboto-Thin.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto-Light';
    src: url('../assets/fonts/Roboto-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto-Regular';
    src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto-Medium';
    src: url('../assets/fonts/Roboto-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Roboto-Bold';
    src: url('../assets/fonts/Roboto-Bold.ttf') format('truetype');
}
