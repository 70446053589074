@import '~scss/variables.scss';

.footer-timeline-controls {
    display: flex;
    justify-content: space-between;
    font-weight: 100;
    font-size: 0.7em;

    font-family: $font-light;
    letter-spacing: 0.6px;

    &__list {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__item {
        margin-right: 10px;

        &:last-child {
            margin-right: unset;
        }
    }

    &__button {
        padding: 5px 10px;
        color: $color-disabled-text;
        background: $color-base-medium;
        border: 2px solid $color-base-medium;
        border-radius: 3px;
        transition: color linear 0.25s, background-color linear 0.25s, border-color linear 0.25s;

        &:hover {
            background: $color-base-light;
            border: 2px solid $color-base-light;
            cursor: pointer;
        }

        &:disabled {
            opacity: 0.5;

            &:hover {
                color: $color-disabled-text;
                background: $color-base-medium;
                border: 2px solid $color-base-medium;
                cursor: text;
            }
        }

        &--hotkeys-modal {
            display: flex;
            align-items: center;
            background-color: transparent;
            svg {
                position: relative;
                width: 20px;
                margin-right: 10px;
                fill: $color-disabled-text;
            }
        }
    }
}
