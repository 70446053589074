@import '../../../../scss/variables';

.railway {
    width: 100%;
    padding: 5px 0;
    cursor: pointer;

    &__line {
        position: relative;
        width: 100%;
        height: 4px;
        background-color: #34353d;
    }

    &__block {
        position: absolute;
        top: 0;
        height: 4px;
        background-color: #4e5a72;
        border-radius: 1px;
        transition: width linear 0.25s, left linear 0.25s, right linear 0.25s;
    }

    &__train {
        position: absolute;
        top: 50%;
        width: 2px;
        height: 6px;
        background-color: #ffb53c;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: left 0.3s ease-out;
    }
}
