/* Margin */
.mt-2 {
    margin-top: 20px;
}

.mt-4 {
    margin-top: 40px;
}

.pb-0 {
    padding-bottom: 0 !important;
}

/* Text */
.text-center {
    text-align: center;
}

/* Inline List */
.inline-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    .list-item {
        margin-right: 1rem;
        &:last-child {
            margin-right: 0;
        }
    }
}

/* 16:9 embed */
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    max-height: 100%;
    padding: 0;
    overflow: hidden;
    &::before {
        display: block;
        content: '';
    }
    &.embed-responsive-16by9::before {
        padding-top: 56.25%;
    }
    .embed-responsive-item {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.pull-right {
    float: right;
}

.align-center {
    justify-content: center;
}

.flex-container {
    display: flex;
    &--align-center {
        align-items: center;
    }
}

.greyscale {
    cursor: unset;
    transition: filter linear 0.25s, opacity linear 0.25s;
    &--disabled {
        opacity: 0.5;
        filter: grayscale(1);
        pointer-events: none;
    }
}
