@import '~scss/variables.scss';

.thumbnail-panel-list {
    display: flex;
    flex-flow: wrap;
}

.thumbnail-panel-item {
    position: relative;
    width: calc(25% - 4px);
    margin: 2px;
    padding: 5px;
    background-color: $background-color-woodsmoke;
    transition: background-color linear 0.25s;

    &:hover {
        z-index: 10;
        cursor: pointer;
        .thumbnail-panel-item__menu {
            opacity: 1;
        }
        .thumbnail-panel-item__thumbnail {
            &:after {
                opacity: 1;
            }
        }
    }

    &--focus {
        background-color: rgba($background-color-lochmara, 0.4);
    }

    &--preview {
        background-color: rgba($color-white, 0.4);
    }

    &__thumbnail {
        position: relative;
        &:after {
            position: relative;
            display: block;
            padding-top: (9 / 16) * 100%;
            background-color: rgba($background-color-woodsmoke, 0.5);
            opacity: 0;
            transition: opacity linear 0.25s;
            content: '';
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }

    &__title {
        color: $text-color-mischka;
        font-size: 14px;
        font-family: $font-light;
    }

    &__time {
        margin-top: 5px;
        color: $text-color-mischka;
        font-size: 12px;
        font-family: $font-light;
    }

    &__actions {
        position: absolute;
        right: 10px;
        bottom: 10px;
        display: flex;
        align-items: center;

        .list-item {
            margin-right: 8px !important; // overrides default list-item margin
            &:last-child {
                margin-right: 0 !important; // overrides the above rule for the last item
            }
        }

        .tooltip__message.tooltip-top {
            // center tooltip within the 16px icon
            margin-left: 0;
        }

        a,
        button {
            display: flex;
            align-items: center;
            color: rgba($color-white, 0.75);

            svg {
                width: 16px;
                height: 16px;
                transition: fill linear 0.25s;
                fill: rgba($color-white, 0.75);
            }

            &:hover {
                color: $color-white;
                text-decoration: none;
                cursor: pointer;

                svg {
                    fill: $color-white;
                }
            }
        }

        .card__actions__text {
            margin-left: 5px;
        }
    }

    .loading-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
