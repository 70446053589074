@import '~scss/variables.scss';

$border-color: $background-color-gallery;

.channel-manager {
    .selected-content {
        &__info-wrapper {
            padding: 0 30px;
        }

        &__text {
            margin-bottom: 15px;
        }

        &__ads {
            padding: 30px 0;
            color: rgba($color-base-dark-blue, 0.9);
            font-size: 0.95em;
        }

        &__ads-label {
            margin-right: 7px;
            padding: 4px 6px;
            color: $color-white;
            font-size: 0.75em;
            font-family: $font-bold;
            line-height: 1;
            background: $color-blue;
            border-radius: 4px;
        }

        &__ads-distribution {
            margin-top: 20px;
            font-size: 0.95em;
            font-family: $font-bold;
        }

        &__pod-options-wrapper {
            border-top: 1px solid $border-color;

            .section-header-text {
                padding-top: 30px;
            }
        }

        .content-card {
            margin-top: 20px;
        }
    }
}
