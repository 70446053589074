@import '~scss/variables.scss';

$box-shadow: rgba($background-color-alto, 0.251);

@keyframes fadeIn {
    0% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

.pod-item {
    position: relative;
    padding-top: 80px;

    &:hover {
        &:not(.pod-item--is-locked):not(.pod-item--is-removed) {
            .pod-card {
                z-index: 1;
                transform: scale(1.1);

                .dropdown-menu,
                &__pod-position-move,
                &__dragger-handle {
                    opacity: 1;
                    transition: opacity 0.4s;
                    pointer-events: auto;
                }

                &__pod-position-display {
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }

        &.pod-item--is-locked {
            .pod-card {
                .dropdown-menu {
                    opacity: 1;
                    transition: opacity 0.4s;
                    pointer-events: auto;
                }

                &__pod-position-display {
                    opacity: 0;
                    pointer-events: none;
                }
            }
        }
    }

    &--is-clickable {
        .pod-card {
            cursor: pointer;
        }

        &:hover {
            .pod-card__action-selector {
                display: flex;

                &--is-selected {
                    display: flex;
                    .pod-card__action-selector {
                        &__checked {
                            display: block;
                        }
                        &__counter {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &--is-locked,
    &--is-removed {
        .pod-card__video-thumbnail {
            padding-top: 43px;
        }
    }

    &--fade-in {
        .pod-card {
            animation: fadeIn 1s ease;
        }
    }

    &--is-live {
        .pod-item {
            &__schedule {
                &__info .pod-item__schedule__dot-icon {
                    border: 1px solid $color-red;
                }

                &__label {
                    color: $color-red;
                }
            }
        }
    }

    &--is-last {
        .pod-item {
            &__schedule {
                &__info {
                    width: 0px;
                }

                &__next-event-in {
                    display: none;
                }
            }
        }
    }

    &__schedule {
        &__info-wrapper {
            position: relative;
            left: 50%;
        }

        &__next-event-in {
            position: absolute;
            top: 20%;
            width: 410px;
            color: rgba($color-base-dark-blue, 0.9);
            font-size: 0.75em;
            letter-spacing: 0.02em;
            text-align: center;
            transition: opacity 0.4s;
        }

        &__info {
            position: relative;
            height: 79px;
            border-right: 0px none;
            border-bottom: 0px none;
            border-left: 1px solid rgba($background-color-alto, 0.722);
        }

        &__dot-icon {
            position: absolute;
            top: 0;
            box-sizing: border-box;
            width: 15px;
            height: 15px;
            color: rgba($color-base-dark-blue, 0.9);
            background: $color-white;
            border: 1px solid $background-color-alto;
            border-radius: 50%;
            transform: translate(-50%, -50%);
        }

        &__label {
            position: absolute;
            top: -40px;
            color: rgba($color-base-dark-blue, 0.9);
            font-size: 1em;
            font-family: $font-bold;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            transform: translateX(-50%);
        }
    }

    &__action-wrapper {
        position: absolute;
        right: -50px;
        z-index: 111111;
        display: grid;
        width: 50px;
        height: 400px;
        place-items: center;

        &:hover {
            .pod-item__action-menu {
                opacity: 1;
                transition: opacity 0.4s;
                pointer-events: auto;
            }
        }

        &.is-first-pod {
            left: -50px;
        }
    }

    &__between-action-wrapper {
        width: 32px;

        .link-menu__three-dots-icon {
            width: 30px;
            height: 30px;
        }
    }

    &__action-menu {
        &.dropdown-menu {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity 0.4s;

            .dropdown-menu__dropdown {
                button {
                    color: $color-blue;
                }

                svg {
                    margin-right: 15px;
                    fill: $color-white;

                    & * {
                        stroke: $color-blue;
                        stroke-opacity: 1;
                    }
                }
            }
        }
    }
}

.pod-card {
    position: relative;
    height: 400px;
    background: $color-white;

    border: 2px solid $color-white;
    border-radius: 8px;
    box-shadow: 0px 0px 13px $box-shadow;
    transition: transform 0.4s;

    &--has-update {
        box-shadow: inset 0 -5px 0 0 $color-blue;
    }

    &--is-selected {
        border: 2px solid $color-blue;

        .pod-card__action-selector {
            display: flex;
            color: $color-white;
            background-color: $color-blue;

            &:hover {
                background: lighten($color-blue, 5);
            }
        }

        &:hover {
            .pod-card__action-selector {
                &__checked {
                    display: block;
                }

                &__counter {
                    display: none;
                }
            }
        }
    }

    &__action-selector {
        position: absolute;
        top: 12px;
        right: 25px;
        display: none;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        font-size: 0.6em;
        border: 2px solid $color-blue;
        border-radius: 50%;
        cursor: pointer;
        transition: background 0.4s;
        animation: fadeIn 0.3s;

        svg {
            height: 10px;
            fill: $color-white;
        }

        &__checked {
            display: none;
        }

        &:hover {
            background: rgba($color-base-dark-blue, 0.1);
        }
    }

    &__more-options {
        position: absolute;
        top: 8px;
        right: 10px;
        z-index: 11;
        opacity: 0;
        transition: opacity 0.4s;
    }

    &__delete-menu-item button {
        color: $color-red;
    }

    &__dragger-wrapper {
        display: flex;
        align-items: center;
        width: 27px;
        height: 28px;
        margin: 0 auto;
        cursor: move;
    }

    &__dragger-handle {
        position: relative;
        top: 10px;
        width: 27px;
        height: 2px;
        background: $background-color-silver;
        opacity: 0;
        transition: opacity 0.4s;

        &:before,
        &:after {
            position: absolute;
            width: 27px;
            height: 2px;
            background: $background-color-silver;
            content: '';
        }

        &:after {
            top: 5px;
        }

        &:before {
            top: -5px;
        }
    }

    &__deleted-overlay {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: $color-white;
        border-radius: 8px;
        box-shadow: 0px 0px 13px $box-shadow;
        opacity: 0.6;
        pointer-events: none;
    }

    &__timeline-bar {
        display: flex;
        height: 6px;

        &__ads {
            background: $background-ad-marker-gradient;
        }

        &__content {
            background: $background-content-marker;
        }
    }

    &__timeline-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 40px;
    }

    &__pod-info {
        padding-bottom: 10px;
        color: rgba($color-base-dark-blue, 0.72);
        font-size: 0.8em;
        letter-spacing: 0.02em;
        text-align: center;
        text-transform: uppercase;

        span {
            font-size: 1.1em;
            font-family: $font-bold;
            letter-spacing: 0.02em;
        }
    }

    &__video {
        &-lock {
            position: absolute;
            bottom: 0;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: $color-white;
            border: 1px solid $background-color-dust-gray;
            border-radius: 50%;
            transform: translate(-50%, 50%);

            svg {
                width: 16px;
                height: 16px;
                fill: $background-color-dust-gray;
            }
        }

        &-thumbnail {
            display: flex;
            padding-top: 15px;
            transition: opacity 0.4s ease;

            img {
                width: 135px;
                height: 76px;
                margin: 0 auto;
                border-radius: 4px;
                opacity: 0;
                transition: opacity 0.4s;
            }

            &--is-loaded {
                img {
                    opacity: 1;
                }
            }
        }

        &-meta {
            height: 125px;
            padding: 0 40px;
        }

        &-title {
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 44px;
            margin: 20px 0;
            font-size: 1.1em;
            line-height: 1.3em;
            letter-spacing: 0.02em;
            text-align: center;

            span {
                display: -webkit-box;
                padding-right: 10px;
                overflow: hidden;
                color: rgba($color-base-dark-blue, 0.9);
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .tooltip {
                align-self: flex-end;
            }
        }

        &-description {
            display: -webkit-box;
            overflow: hidden;
            color: $color-base-dark-blue;
            font-size: 0.75em;
            font-family: $font-light;
            line-height: 1.25em;
            letter-spacing: 0.02em;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }

    &__pod-edit-state {
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: 1;
        padding-bottom: 15px;
        color: rgba($color-base-dark-blue, 0.9);
        font-size: 0.75em;
        line-height: 0.9em;
        letter-spacing: 0.02em;
        transform: translateX(-50%);

        svg {
            width: 15px;
            height: 15px;
            margin-top: -3px;
            margin-right: 3px;
            fill: rgba($color-base-dark-blue, 0.9);
        }

        button {
            color: $text-color-lochmara;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__pod-position {
        position: absolute;
        margin: 10px 0 0 5px;

        &-fixed,
        &-display {
            position: absolute;
            left: 0;
            color: rgba($color-base-dark-blue, 0.5);
            font-size: 1.1em;
            letter-spacing: 0.02em;
            transition: opacity 0.4s;
        }

        &-fixed {
            top: 0;
            width: 90px;
            height: 28px;
            margin: 11px 0 0 5px;
            text-align: center;
        }

        &-display {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        &-move {
            opacity: 0;
            pointer-events: none;
        }
    }
}

.sortable-item {
    &__date-display {
        position: absolute;
        top: 0;
        color: rgba($color-base-dark-blue, 0.72);
        font-size: 0.75em;
        font-family: $font-regular;
        line-height: 0.875em;
        letter-spacing: 0.09em;
        text-transform: uppercase;
        opacity: 1;
        transition: opacity 0.3s;

        &--end {
            right: 0;
        }

        &--hide {
            opacity: 0;
        }
    }

    .channel-manager__pods-scroller--is-sorting & {
        .pod-item {
            pointer-events: none;

            &__schedule__next-event-in {
                opacity: 0;
                transition: opacity 0.4s;
            }
        }

        .sortable-item__date-display {
            opacity: 0;
        }
    }

    .channel-manager__pods-scroller--is-updating & {
        .sortable-item__date-display {
            opacity: 0;
        }
    }

    &--is-active {
        .pod-item {
            &__schedule__next-event-in {
                opacity: 0;
            }

            .pod-card {
                transform: scale(1.1);

                &__dragger-handle {
                    opacity: 1;
                }
            }
        }

        .sortable-item__date-display {
            opacity: 0;
        }
    }
}
