@import '~scss/variables.scss';

.thumbnail-content {
    position: relative;
    &:before {
        position: relative;
        display: block;
        padding-top: (9 / 16) * 100%;
        background-color: rgba($background-color-woodsmoke, 0.5);
        opacity: 0;
        transition: opacity linear 0.25s;
        content: '';
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    .loading-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
