@import '~scss/variables.scss';

$transition-speed: 0.5s;

.channel-manager {
    .controls-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        background: $color-white;

        &__copy-paste-text {
            display: grid;
            grid-template: 1fr/50px max-content;
            align-items: center;
            justify-items: center;
            padding-right: 20px;
            color: rgba($color-base-dark-blue, 0.75);
            font-size: 1em;
            font-family: $font-bold;
            line-height: 1.1em;
            letter-spacing: 0.02em;

            &.copy-paste--transition {
                &-enter {
                    transform: translateY(20px);
                    opacity: 0;
                }
                &-enter-active {
                    transform: translateY(0);
                    opacity: 1;
                }
                &-exit-active {
                    transform: translateY(20px);
                    opacity: 0;
                }
                &-enter-active,
                &-exit-active {
                    transition: transform $transition-speed, opacity $transition-speed;
                }
            }
        }

        &__pending-action-section {
            display: flex;
            align-items: center;
        }

        .loading-spinner {
            margin-left: 10px;
        }

        .pending-action {
            display: -webkit-box;
            margin-right: 40px;
            overflow: hidden;
            color: rgba($color-base-dark-blue, 0.9);
            font-size: 0.95em;
            letter-spacing: 0.02em;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            &--transition {
                &-enter {
                    opacity: 0;
                }
                &-enter-active {
                    opacity: 1;
                }
                &-exit {
                    opacity: 1;
                    // hide pending changes on exit so it doesn't conflict with the Copy and paste mode animation
                    .controls-section__pending-action-section {
                        opacity: 0;
                    }
                }
                &-exit-active {
                    opacity: 0;
                }
                &-enter-active,
                &-exit-active {
                    transition: opacity $transition-speed;
                }
            }

            &__number-to-word {
                text-transform: capitalize;
            }

            &__updated-items {
                flex: 1;
                padding-left: 5px;
                color: $color-blue;
                font-family: $font-bold;
            }

            &__button {
                min-width: 155px;
                height: 61px;
                color: $color-white;
                font-family: $font-bold;
                text-align: center;
                text-transform: uppercase;
                background: $color-blue;
                border-radius: 4px;
                transition: background 0.3s;

                &--submit {
                    margin-right: 10px;

                    &:disabled {
                        background-color: $background-color-alto;
                        cursor: not-allowed;
                    }
                }

                &--discard {
                    color: rgba($color-base-dark-blue, 0.7);
                    background: transparent;
                }

                &--remove {
                    background-color: $color-red;
                }
            }
        }
    }
}
