@import '~bootstrap/dist/css/bootstrap-reboot.css';
@import '../../node_modules/bootstrap-daterangepicker/daterangepicker.css';

@import './fonts';
@import './variables';
@import './helpers';
@import './forms';

body {
    margin: 0;
    padding: 0;
    font-family: $font-regular;
}

button {
    margin: 0;
    padding: 0;
    color: unset;
    background: none;
    border: none;

    &:focus {
        outline: none;
    }
}

.hidden {
    display: none;
}

.spinner-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.page--dark {
    color: $color-white;
    background-color: $color-base-dark;

    ::-webkit-scrollbar {
        width: 10px;
        -webkit-appearance: none;
    }

    ::-webkit-scrollbar-thumb {
        background-color: $color-base-medium;
    }

    ::-webkit-scrollbar-button {
        background: $color-black;
        background-color: $color-black;
    }

    .spinner-wrapper {
        background-color: $color-base-dark;
    }
}

.page--dark-blue {
    background-color: $color-base-dark;
}

.page--light {
    color: $color-black;
    background-color: $color-white;

    .fixed-header {
        position: absolute;
    }

    .avatar-wrapper {
        background: $color-base-dark-blue;
    }
}
