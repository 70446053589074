@import '../../../../scss/variables';

.toast__slice {
    position: relative;
    padding: 10px;
    color: $color-font-light;
    background-color: rgba($color-white, 0.8);
    border-radius: 5px;

    &--light {
        width: 350px;
        background-color: $color-white;
        border: 1px solid rgba($background-color-gallery, 0.6);
        border-radius: 8px;
        box-shadow: 0px 0px 18px rgba($background-color-silver, 0.25);

        .toast__body {
            padding: 20px 0;

            .toast__icon {
                width: 35px;
                height: 35px;
            }

            .toast__content {
                display: flex;
                align-items: center;

                .toast__message {
                    color: rgba($color-base-dark-blue, 0.9);
                    font-size: 16px;
                    font-family: $font-regular;
                    line-height: 19px;
                    letter-spacing: 0.02em;
                }
            }
        }

        &.toast__slice--danger,
        &.toast__slice--success {
            .toast__icon {
                fill: rgba($color-base-dark-blue, 0.9);
            }
        }
    }

    &--success {
        .toast__icon {
            fill: $color-success;
        }
    }
    &--warning {
        .toast__icon {
            fill: $color-warning;
        }
    }
    &--danger {
        .toast__icon {
            fill: $color-danger;
        }
    }
    &--info {
        .toast__icon {
            fill: $color-info;
        }
    }
}

.toast__close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    .close-btn__icon {
        display: block;
        width: 25px;
        height: 25px;
        fill: $color-font-light;
    }
}

.toast__body {
    display: flex;
    .toast__icon {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }

    .toast__content {
        .toast__title {
            margin-bottom: 5px;
            font-weight: 400;
            font-size: 16px;
        }

        .toast__message {
            margin-bottom: 0;
            font-size: 12px;
            font-family: $font-light;
        }
    }
}
