@import '~scss/variables.scss';

.timeline-preview {
    position: absolute;
    top: -15px;
    width: 100%;
    height: 85px;

    &__player {
        display: flex;
    }

    .loading-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
    }

    &--is-active {
        .timeline-preview__wrapper {
            opacity: 1;
        }
    }

    &--is-seeking {
        .loading-spinner {
            opacity: 1;
        }

        video {
            opacity: 0;
        }
    }

    &__ghost-playhead {
        opacity: 0;

        &.playhead {
            background: $background-color-osiogray;
            pointer-events: none;

            .the-head {
                background: inherit;

                &__tip {
                    border-top-color: $background-color-osiogray;
                }
            }
        }
    }

    &__wrapper {
        position: absolute;
        top: 3px;
        z-index: 10;
        box-sizing: content-box;
        width: 160px;
        height: 98px;
        border: 1px solid #33353d;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba($color-black, 0.5);
        transform: translateX(-50%);
        cursor: pointer;
        opacity: 0;
        transition: opacity 0.3s;
        pointer-events: none;
    }

    &__modal {
        position: relative;
        top: unset;
        left: unset;
        z-index: 1001;
        width: 100%;
        padding: 10px;
        background-color: $color-base-dark;
        transform: unset;

        .modal__body {
            video {
                width: 100%;
            }
        }
    }
}
