/*
     The legacy css framework is overriding the rules coming from the styled componenets. This file re-aligns those rules.
*/
[class*='AuthenticationContainer'] {
    [class*='StyledLabelContent'] {
        margin-top: unset;
        margin-bottom: unset;
    }

    [class*='StyledTextFieldInput'] {
        margin-top: unset;
        margin-bottom: unset;
        color: #fff !important;
        background: rgba(#fff, 0.1) !important;

        &:hover {
            border-color: rgba(#fff, 0.5) !important;
        }

        &:disabled {
            border-color: rgba(#fff, 0.2) !important;
        }
    }
}
