@import '~scss/variables.scss';

.timeline-section {
    padding: 18px 20px;
    border-top: 1px solid #393e46;
}

.timeline-overlay-wrapper {
    &:hover {
        .timeline-preview__ghost-playhead {
            opacity: 1;
        }
    }
}

.timeline {
    display: flex;
    &__tick {
        flex: 1 1 0;
        height: 100px;
        transition: 0.3s linear flex;

        &__label {
            position: absolute;
            color: $color-tick-label;
            font-size: 12px;
            transform: translateX(-50%);
            user-select: none;

            &--left {
                left: 0;
                transform: translateX(0);
            }
            &--right {
                right: 0;
                transform: translateX(0);
            }
        }

        &--large {
            display: flex;
            padding-top: 35px;

            &:before {
                position: absolute;
                height: 55px;
                border-left: 1px solid $color-tick-marker;
                content: '';
            }
            &:last-child {
                &:after {
                    position: absolute;
                    height: 70px;
                    border-left: 1px solid $color-tick-marker;
                    content: '';
                }
            }

            .sub-tick,
            .minor-tick {
                position: relative;
                display: flex;
                flex-grow: 1;
                height: 50%;
                pointer-events: none;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 1px;
                    height: 100%;
                    background-color: $color-tick-marker;
                    content: '';
                }

                &:first-child {
                    &:before {
                        display: none;
                    }
                    border-left: none;
                }
            }
        }

        &.example-enter {
            flex: 0 0 0;
        }
    }
}

.playhead-wrapper {
    position: absolute;
    top: -15px;
    width: 100%;
}

.playhead {
    position: absolute;
    top: 12px;
    z-index: 5;
    width: 1px;
    height: 73px;
    background-color: $color-yellow;
    &.animate {
        transition: 0.25s linear left;
    }
    .the-head {
        position: absolute;
        top: -10px;
        left: -4px;
        width: 8px;
        height: 10px;
        background-color: #f5a623;
        border-radius: 2px 2px 0 0;
        box-shadow: 0 2px 4px 0 rgba($color-black, 0.5);

        &__tip {
            position: absolute;
            bottom: -8px;
            display: block;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-top-color: #f5a623;
        }
    }

    &__time {
        display: none;
        padding: 10px;
        font-size: 0.7em;
    }

    &:hover {
        .playhead__time {
            display: block;
            pointer-events: none;
        }
    }

    &--live {
        background-color: $color-red;
        .the-head {
            background: $color-red;
            &__tip {
                border-top-color: $color-red;
            }
        }
    }
}

.timeline-overlay-wrapper {
    position: absolute;
    width: 100%;
    height: 100px;
    margin-top: 35px;
}

.timeline-v2 {
    outline: none;
    &::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        height: 0;
        background: transparent; /* Optional: just make scrollbar invisible */
    }
}

.timeline__tick-wrapper {
    position: relative;
    height: 105px !important;
}
