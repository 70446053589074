@import '../../../scss/variables.scss';

$switch-width: 45px;
$switch-height: 20px;

.toggle-switch {
    position: relative;
    z-index: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    width: $switch-width * 2;
    margin-top: 10px;
    background-color: $background-color-osiogray;
    border-radius: 4px;

    &--button {
        position: relative;
        display: inline-block;
        width: $switch-width;
        height: $switch-height;
        font-size: 10px;
        font-family: $font-light;
        border-radius: 4px;
        cursor: pointer;

        &.active {
            cursor: default;

            &:before {
                color: $color-base-dark-blue;
                font-weight: bold;
            }

            &:after {
                width: $switch-width;
                height: 100%;
                background-color: rgba($color-white, 0.9);
                transition-delay: 300ms;
            }

            &:first-child {
                &:after {
                    transform: translateX(0px);
                    transition: transform 300ms ease-out;
                }
            }

            &:last-child {
                &:after {
                    transform: translateX(0px);
                    transition: transform 300ms ease-out;
                }
            }
        }

        &:after {
            top: 0px;
            z-index: 0;
            display: inline-block;
            height: 100%;
            border-radius: 4px;
            content: '';
        }

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: inline-block;
            width: $switch-width;
            height: 100%;
            color: $background-color-silver;
            line-height: $switch-height;
            text-align: center;
            border-radius: 4px;
            content: attr(title);
        }

        &:first-child {
            &:before {
                transform: translateX(0px);
            }

            &:after {
                transform: translateX($switch-width);
                transition: transform 300ms ease-out;
            }
        }

        &:last-child {
            &:after {
                transform: translateX(-$switch-width);
                transition: transform 300ms ease-out;
            }
        }
    }
}
