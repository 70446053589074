@import './variables';

.form-group {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__textfield-with-animate {
        // fix height of input to animate
        height: 60px;
        transition: height 0.25s linear;
    }

    &--hidden {
        height: 0;
        overflow: hidden;
    }

    &__label:not(.no-transition) {
        position: absolute;
        top: 0;
        left: 0;
        margin-bottom: 0;

        color: $text-color-santas-gray;
        font-size: 12px;
        font-family: $font-light;
        line-height: 14px;
        transform: translate(0, 18px);
        cursor: text;
        transition: transform ease-in-out 0.2s;

        -webkit-font-smoothing: antialiased;
    }

    &__clear {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-20px, -50%);

        opacity: 0;
        transition: transform ease-in-out 0.2s, opacity ease-in-out 0.2s;
    }

    &__control-wrapper {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
    }

    &__control-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.7;
        pointer-events: none;

        + .form-group__control {
            padding-left: 25px;
            + .form-group__label {
                transform: translate(25px, 22px);
            }
            + .interaction {
                .form-group__label {
                    transform: translate(25px, 22px);
                }
            }
        }
    }

    &__label {
        color: #979aa9;
        font-size: 12px;
        font-family: $font-light;
        line-height: 14px;
        cursor: text;
    }

    &__control {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 16px;
        font-family: $font-light;
        line-height: 19px;
        border: none;
        transition: border-bottom-color 0.5s ease-out 0.1s;

        .page--dark & {
            color: $color-white;
            background-color: $color-base-dark;
            border-bottom: 1px solid #23262c;
        }

        &:focus,
        &:not([value='']),
        &.has-value {
            outline: none;
            + .form-group__label {
                transform: translate(0, 0);
            }
            + .interaction {
                .form-group__label {
                    transform: translate(0, 0);
                }
            }
        }

        &:not([value='']) {
            + .interaction {
                .form-group__clear {
                    transform: translate(0, -50%);
                    opacity: 1;
                }
            }
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background-color 0s linear 2147483647s;
            -webkit-text-fill-color: $color-white !important;
            + .form-group__label {
                transform: translate(0, 0);
            }
            + .interaction {
                .form-group__label {
                    transform: translate(0, 0);
                }
            }
        }

        &::placeholder {
            color: transparent;
        }

        &[type='checkbox'] {
            margin-right: 10px;
        }

        & + .form-group__label {
            position: absolute;
            top: 0;
            left: 0;
            margin-bottom: 0;
            transform: translate(0, 22px);
            transition: transform ease-in-out 0.2s;

            -webkit-font-smoothing: antialiased;
        }
    }

    &__helpblock {
        position: absolute;
        bottom: 0;
        color: $color-red;
        font-size: 12px;
        line-height: 10px;
        transform: translateY(10px);
        transition: transform 0.2s ease-out 0.1s;

        -webkit-font-smoothing: antialiased;
    }

    .button {
        flex-grow: 0;
    }

    &--has-error {
        .form-group__helpblock {
            transform: translateY(-5px);
        }
        .form-group__control {
            border-bottom-color: $color-red;
        }
    }
}

.button-group {
    .button + .button {
        margin-left: 15px;
    }
}
