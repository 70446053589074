@import '~scss/variables.scss';

.pod {
    &__order-edit {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__order-input {
        width: 50px;
        min-width: 0;
        color: rgba($color-base-dark-blue, 0.5);
        text-align: center;
        border: rgba($color-base-dark-blue, 0.5) 1px solid;
        border-radius: 2px;

        &--has-error {
            outline-color: $color-red;
        }
    }

    &__order-btn {
        width: 20px;
        height: 20px;
        margin-top: -6px;
        fill: rgba($color-base-dark-blue, 0.5);

        &:disabled {
            opacity: 0.3;
        }
    }
}
