@import '~scss/variables.scss';

$box-shadow-color: rgba(140, 156, 176, 0.3);

.channel-manager {
    .selected-content-list {
        position: sticky;
        bottom: 0;
        z-index: 10;
        max-height: 100px;
        padding: 20px;
        overflow: hidden;
        background: $color-white;
        box-shadow: 0 4px 20px 0 $box-shadow-color;
        transition: max-height 0.7s cubic-bezier(0, 1, 0, 1);

        &--is-expanded {
            max-height: 100vh;
            overflow: auto;
            transition: max-height 0.7s ease-in-out;

            .selected-content-list {
                &__info-chevron {
                    svg {
                        transform: rotate(90deg);
                        transition: transform 0.5s;
                    }
                }

                &__list {
                    opacity: 1;
                    transition: opacity 0.3s ease 0.2s;
                }
            }
        }

        &__error {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
            color: $color-red;
            font-size: 0.8em;

            svg {
                margin-right: 5px;
                fill: $color-red;
            }
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin: 20px 0 80px 0;
            padding: 20px 0;
            border-top: 1px solid rgba($color-base-dark-blue, 0.1);
            border-bottom: 1px solid rgba($color-base-dark-blue, 0.1);
            opacity: 0;
            transition: opacity 0.3s;
        }

        &__info-chevron {
            display: grid;
            width: 44px;
            height: 44px;
            border: 1px solid rgba($color-base-dark-blue, 0.1);
            cursor: pointer;
            place-content: center;

            svg {
                transform: rotate(-90deg);
                transition: transform 0.3s;
                fill: rgba($color-base-dark-blue, 0.4);
            }
        }

        &__info-wrapper {
            display: flex;
            align-items: center;
            padding-top: 8px;
            padding-right: 90px;
        }

        &__list-count {
            justify-self: flex-start;
            padding-left: 20px;
            font-size: 1em;
        }

        &__add-button {
            position: absolute;
            right: 20px;
            bottom: 20px;
            height: 60px;
            padding: 0 20px;
            color: $color-white;
            font-size: 1em;
            font-family: $font-bold;
            text-align: center;
            text-transform: uppercase;
            background: $color-blue;
            border-radius: 4px;
            transition: background 0.3s;

            &:disabled {
                background: $background-color-alto;
            }
        }

        &__list-item {
            position: relative;
            display: flex;
            align-items: center;
            height: 22px;
            margin-right: 5px;
            margin-bottom: 5px;
            padding: 0 20px 0 10px;
            color: $color-white;
            background-color: $color-blue;
            border-radius: 13px;
            cursor: pointer;

            &--has-error {
                background: rgba($color-red, 0.75);

                .selected-content-list__item-position {
                    background: $color-red;
                }
            }
        }

        &__item-position {
            display: grid;
            width: 30px;
            height: 13px;
            margin-right: 5px;
            font-weight: 900;
            font-size: 0.65em;
            line-height: 1;
            background: lighten($color: $color-blue, $amount: 8);
            border-radius: 13px;
            place-content: center;
        }

        &__item-title {
            display: -webkit-box;
            max-width: 230px;
            overflow: hidden;
            font-size: 0.7em;
            line-height: 1;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        &__remove-item {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 5px;
            cursor: pointer;
            pointer-events: unset;

            svg {
                width: 12px;
                height: 12px;
                fill: $color-white;
            }
        }
    }
}
