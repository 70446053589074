@import '~scss/variables.scss';

.scroll-area {
    position: relative;
    display: flex;
    flex-flow: nowrap;
    gap: 5px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
        height: 0; /* Remove scrollbar space */
    }

    &__scroller {
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-scrollbar {
            display: none;
            width: 0;
            -webkit-appearance: none;
        }
    }

    .scroll-arrow {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        width: 30px;
        height: 27px;
        &--left {
            left: 0;
            justify-content: flex-start;
            background: linear-gradient(90deg, $color-white 0%, rgba($color-white, 0) 100%);
        }
        &--right {
            right: 0;
            justify-content: flex-end;
            background: linear-gradient(270deg, $color-white 0%, rgba($color-white, 0) 100%);
        }

        svg {
            width: 14px;
            height: 14px;
            fill: rgba($color-base-dark-blue, 0.9);
        }
    }
}
