@import '~scss/variables.scss';

.card {
    position: relative;
    display: flex;
    margin: 10px 10px 0 0;
    background: rgb(21, 21, 28);
    transition: background-color 0.3s ease-in;

    div.card-content,
    a.card-content {
        display: flex;
        align-items: flex-start;
        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    a.card-content {
        cursor: pointer;
    }

    &--full-width {
        width: 100%;
        max-width: unset;
    }

    // todo: refactor, card should not be aware of `media type`
    &--horizontal,
    &--horizontal-live {
        display: flex;
        .card-content {
            align-items: stretch;
            width: 100%;

            .card__thumbnail {
                flex-grow: 0;
            }

            .card__description {
                display: block;
            }

            .card__title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @supports (-webkit-line-clamp: 2) {
                    display: -webkit-box;
                    overflow: hidden;
                    white-space: unset;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
            }
        }
    }

    &--horizontal {
        .card__title {
            padding-right: 20px;
        }
    }

    &--horizontal-live {
        width: 390px;
    }

    &--vertical {
        width: 190px;

        .card-content {
            flex-wrap: wrap;

            .card__description {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                min-height: 152px;
                padding-bottom: 50px;
            }
        }
    }
}

.card__thumbnail {
    position: relative;
    display: flex;
    flex-grow: 0;
    &--auto {
        img {
            width: 100%;
        }
    }
}

.card__tag-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 5px;
}

.card__tag {
    position: absolute;
    right: 5px;
    bottom: 5px;
    width: auto;
    padding: 2px 4px;
    color: $color-white;
    font-weight: 100;
    font-size: 0.6em;
    font-family: $font-light;
    text-transform: uppercase;
    border-radius: 3px;
    opacity: 0.7;

    &--time {
        background: black;
    }

    &--live {
        background-color: $color-red;
        opacity: 1;
    }
}

.card__status-badge {
    display: flex;
    &--justify-right {
        justify-content: flex-end;
    }
}

.card__description {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 15px;
    color: $color-white;
    font-family: $font-light;
}

.card__title {
    color: rgba($color-white, 0.9);
    font-size: 14px;
    line-height: 18px;
    word-break: break-word;

    @supports (-webkit-line-clamp: 3) {
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
}

.card__subtitle {
    margin-top: 5px;
    color: $color-font-light;
    font-size: 12px;

    & + .card__subtitle {
        margin-top: 0;
    }
}

.card__processing-status {
    position: absolute;
    bottom: 10px;
    left: 15px;
    cursor: pointer;

    .card--horizontal & {
        top: 10px;
        right: 10px;
        bottom: unset;
        left: unset;
    }

    svg {
        display: block;
        width: 16px;
        height: 16px;
    }

    .card--has-warning & {
        svg {
            fill: $color-warning;
        }
    }
    .card--has-error & {
        svg {
            fill: $color-danger;
        }
    }
    .card--has-info & {
        svg {
            fill: $color-info;
        }
    }
}

.card__actions {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    align-items: center;

    .list-item {
        margin-right: 8px !important; // overrides default list-item margin
        &:last-child {
            margin-right: 0 !important; // overrides the above rule for the last item
        }
    }

    .tooltip__message.tooltip-top {
        // center tooltip within the 16px icon
        margin-left: 8px;
    }

    a,
    button {
        display: flex;
        align-items: center;
        color: rgba($color-white, 0.75);

        svg {
            width: 16px;
            height: 16px;
            transition: fill linear 0.25s;
            fill: rgba($color-white, 0.75);
        }

        &:hover {
            color: $color-white;
            text-decoration: none;
            cursor: pointer;

            svg {
                fill: $color-white;
            }
        }
    }

    .card__actions__text {
        margin-left: 5px;
    }
}

.card__processing-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color-base-dark, 0.5);
}
