@import '~scss/variables.scss';

.character-range {
    position: absolute;
    top: 0;
    right: 0;
    color: $text-color-santas-gray;
    font-size: 12px;
    font-family: $font-light;
    line-height: 14px;
    transition: color linear 0.25s;
    -webkit-font-smoothing: antialiased;

    &--warning {
        color: #ffa500;
    }
    &--danger {
        color: #ff0000;
    }
}
