.toast-rack {
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 10000;
    width: 350px;
    overflow: visible;
}

.toast__list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.toast__list {
    .toast__slice {
        margin-bottom: 10px;
        animation: toasterPop 0.5s;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

@keyframes toasterPop {
    0% {
        transform: translateX(150px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
