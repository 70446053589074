@import '~scss/variables.scss';

.clips-rail {
    position: absolute;
    width: 100%;

    &--preview-focus,
    &--active-focus,
    &--hover-focus {
        .clip-range {
            opacity: 0.75;
            filter: grayscale(1);
        }
    }

    &--active-focus {
        .clip-range.clip-range--hover:not(.clip-range--active) {
            opacity: 0.75;
            filter: grayscale(1);
        }
    }

    .clip-range {
        position: absolute;
        top: 0;
        left: 0;
        height: 70px;
        background-color: rgba($background-color-lochmara, 0.4);
        transition: opacity linear 0.25s, background-color linear 0.25s, transform linear 0.25s;

        &:hover,
        &--hover {
            .clip-range__title,
            .clip-range__time-range {
                opacity: 1;
            }
        }

        &--hover,
        &--active {
            z-index: 20;
            opacity: 1;
            filter: grayscale(0);
        }

        &--invalid {
            background-color: rgba($color-danger, 0.4);

            .clip-range__handle {
                background-color: $color-danger;

                &:before {
                    background-color: $color-white;
                }
            }

            .clip-range__time-range,
            .clip-range__title {
                color: $color-white;
                background-color: $color-danger;
            }

            .clip-range__status {
                svg {
                    fill: $color-white;
                }
            }
        }

        &--preview {
            z-index: 10;
            background-color: rgba($color-white, 0.4);
            opacity: 1 !important; // override blanket opacity of children from parent

            .clip-range__handle {
                background-color: $color-white;

                &:before {
                    background-color: $background-color-osiogray;
                }
            }
        }

        &__handle {
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10px;
            background-color: $background-color-lochmara;
            transition: background-color linear 0.25s;

            &--in {
                left: 0;
                border-radius: 4px 0 0 4px;
                transform: translateX(-100%);
            }

            &--in-solo {
                left: 0;
                border-radius: 4px;
                transform: translateX(-100%);
            }

            &--out {
                right: 0;
                border-radius: 0 4px 4px 0;
                transform: translateX(100%);
            }

            &:before {
                width: 2px;
                height: 40%;
                background-color: $color-white;
                opacity: 0.6;
                transition: background-color linear 0.25s;
                content: '';
            }
        }

        &__title {
            position: absolute;
            right: 5px;
            bottom: 5px;
            max-width: 150px;

            padding: 2px 4px;
            overflow: hidden;
            color: $color-white;
            font-size: 12px;

            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: $background-color-lochmara;
            border-radius: 2px;
            opacity: 0;
            transition: color linear 0.25s, background-color linear 0.25s, opacity linear 0.25s;
        }

        &__time-range {
            position: absolute;
            top: 5px;
            right: 5px;

            padding: 2px 4px;
            color: $color-white;
            font-size: 12px;
            background-color: $background-color-lochmara;
            border-radius: 2px;
            opacity: 0;
            transition: color linear 0.25s, background-color linear 0.25s, opacity linear 0.25s;
        }

        &__status {
            display: flex;
            align-items: center;

            svg {
                display: inline-block;
                width: 12px;
                height: 12px;
                margin-right: 5px;
            }
        }

        &--preview {
            .clip-range__title {
                color: rgba($background-color-osiogray, 0.7);
                background-color: $color-white;
            }

            .clip-range__time-range {
                color: rgba($background-color-osiogray, 0.7);
                background-color: $color-white;
            }
        }
    }
}
