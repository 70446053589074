@import '~scss/variables.scss';

.thumbnails-rail {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 24px;
    transition: opacity linear 0.25s;
    &--hidden {
        opacity: 0;
        pointer-events: none;
    }
}
