@import '../../../scss/variables';

.button {
    color: $color-white;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;

    transition: background-color ease-in-out 0.2s, border-top-color ease-in-out 0.2s, border-right-color ease-in-out 0.2s,
        border-bottom-color ease-in-out 0.2s, border-left-color ease-in-out 0.2s, color ease-in-out 0.2s, opacity linear 0.2s;
    fill: $color-white;

    &:hover {
        text-decoration: none;
    }

    &:disabled {
        cursor: auto;
        opacity: 0.5;
    }

    &--large {
        padding: 10px 25px;
        font-size: 12px;
        text-transform: uppercase;
    }

    &--small {
        padding: 8px 10px;
        font-size: 11px;
    }

    &--black {
        color: $color-disabled-text;
        background-color: $background-color-cinder;
        border-color: $color-disabled-text;
        fill: $color-disabled-text;

        &:hover {
            color: $color-white;
            background-color: $color-blue;
            border-color: transparent;
            fill: $color-white;
        }

        &:disabled {
            &:hover {
                color: $color-disabled-text;
                background-color: $background-color-cinder;
                border-color: $color-disabled-text;
                fill: $color-disabled-text;
            }
        }
    }

    &--blue {
        background-color: $color-blue;
        border-color: $color-blue;

        &:hover {
            color: $color-white;
            background-color: $background-color-woodsmoke;
            border-color: $color-white;
            fill: $color-white;
        }

        &:disabled {
            &:hover {
                background-color: $color-blue;
                border-color: $color-blue;
            }
        }

        &.button--disabled {
            &:hover {
                background-color: $color-blue;
                border-color: $color-blue;
            }
        }
    }

    &--grey {
        color: $color-disabled-text;
        background-color: $color-base-medium;
        border-color: $color-base-medium;
        fill: $color-disabled-text;

        &:hover {
            color: $color-white;
            background-color: $color-blue;
            border-color: transparent;
            fill: $color-white;
        }

        &:disabled {
            &:hover {
                color: $color-disabled-text;
                background-color: $color-base-medium;
                border-color: $color-base-medium;
                fill: $color-disabled-text;
            }
        }
    }

    &:focus {
        outline: none;
    }
}
