@import '~scss/variables.scss';

.player-controls-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.25s linear;
    transition-delay: 1s;

    button {
        pointer-events: none;
    }

    &:hover {
        opacity: 1;
        transition-delay: 0s;

        button {
            pointer-events: auto;
        }
    }

    .footer-controls {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 5px;
        background-color: rgba($color-black, 0.5);

        .player-control {
            svg {
                width: 20px;
                height: 20px;
                fill: $color-white;
            }
        }
    }
}

.player-feedback-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);

    .loading-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        transform: translate(-50%, -50%);
        .animate {
            border-top-color: $color-white;
        }
        .static-ring {
            border-color: rgba($color-base-light, 0.5);
        }
    }

    .player-action-button {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
        transform: translate(-50%, -50%);
        .player-icon {
            width: 40px;
            height: 40px;
            fill: $color-white;
        }
    }
}
