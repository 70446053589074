@import '~scss/variables.scss';

.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    color: $color-white;
    background: linear-gradient(107.56deg, rgba($color-white, 0.08) 0%, rgba($color-white, 0) 100%), $color-base-dark-blue;

    &__logo-container {
        width: 50px;
        height: 50px;
        margin: 30px 0 100px 0;
        padding: 9px;

        img {
            width: 50px;
            height: 50px;
        }
    }

    &__welcome-message {
        font-size: 1.625em;
        font-family: $font-thin;
        line-height: 1.875em;
        letter-spacing: 0.2em;
        text-align: center;
        text-transform: uppercase;
    }

    &__app-name {
        font-size: 5em;
        font-family: $font-bold;
        letter-spacing: 0.02em;
        text-transform: initial;
    }

    &__modes-container {
        display: flex;
        justify-content: space-between;
        width: 750px;
        margin-top: 80px;
    }

    &__mode {
        position: relative;
        width: 321px;
        height: 256px;
        overflow: hidden;
        transition: transform 0.2s;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            color: $color-white;
            font-weight: bold;
            font-size: 1.75em;
            font-family: $font-regular;
            letter-spacing: 0.1em;
            text-transform: uppercase;

            &:hover {
                color: $color-white;
                text-decoration: underline;
            }
        }

        &:focus,
        &:hover {
            transform: perspective(1px) scale(1.05);
        }
    }

    &--edit-mode {
        background: linear-gradient(122.58deg, rgba($color-white, 0.2) 2.62%, rgba($color-white, 0) 100%), rgba($color-base-dark-blue, 0.9);
        border-radius: 10px;

        .landing-page__background {
            &--top {
                position: absolute;
                top: 36px;
                right: -13px;
                transform: rotate(141deg);
            }

            &--bottom {
                position: absolute;
                bottom: 36px;
                left: -13px;
                transform: rotate(-39deg);
            }
        }
    }

    &--channels-mode {
        background: linear-gradient(237.54deg, rgba($color-white, 0.2) 0%, rgba($color-white, 0) 100%), rgba($color-base-dark-blue, 0.9);
        border-radius: 10px;

        .landing-page__background {
            &--top {
                position: absolute;
                top: 40px;
                left: 20px;
            }

            &--bottom {
                position: absolute;
                right: 20px;
                bottom: 40px;
            }
        }
    }

    &__background {
        position: relative;
        width: 120px;
        height: 5px;
        background: rgba($color-white, 0.1);

        &:before,
        &:after {
            position: absolute;
            width: 120px;
            height: 5px;
            background: rgba($color-white, 0.1);
            content: '';
        }

        &:after {
            top: 15px;
        }

        &:before {
            top: -15px;
        }
    }
}
