@import '../../../scss/variables.scss';

.live-badge {
    position: absolute;
    top: 20px;
    right: 20px;

    display: flex;
    align-items: center;

    padding: 5px 10px;
    font-weight: 200;

    font-family: $font-regular;
    letter-spacing: 1.2px;

    background: rgba($color-base-medium, 0.8);
    border: 2px solid $color-red;
    border-radius: 5px;

    &__icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        fill: $color-red;
    }

    &__text {
        color: $color-red;
        font-size: 20px;
        text-transform: uppercase;
    }
}
