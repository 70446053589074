@import '~scss/variables.scss';

.link-menu {
    ul {
        margin: 0;
        padding: 0;
    }

    &__menu-item {
        text-align: center;
        list-style: none;

        button {
            width: max-content;
            padding: 5px 10px;

            // default theme styling
            font-size: 12px;
            font-family: $font-light;

            .page--light & {
                padding: 15px 20px;
                font-size: 14px;
                font-family: $font-bold;
                line-height: 16px;
                letter-spacing: 0.02em;
                text-transform: uppercase;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    // fallback icon
    &__three-dots-icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        fill: rgba($color-white, 0.5);

        .page--light & {
            width: 33px;
            height: 33px;
            background: $color-white;
            border: 1px solid $background-color-alto;
            border-radius: 50%;

            svg {
                width: 20px;
                fill: rgba($background-color-silver, 0.5);
            }
        }
    }
}
