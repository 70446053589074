@import '../../../scss/variables.scss';

// copied from css framework
// refactored to scss

.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    font-size: 1.1rem;
    text-align: center;

    .page--item {
        display: inline-block;
        min-width: 40px;
        height: 40px;
        line-height: 40px;
        background: $color-white;
        border: solid 1px rgba($color-base-dark-blue, 0.2);
        transition: border-bottom ease 0.4s, color ease 0.4s;

        &:first-child {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }

        &:last-child {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
        }

        &:hover,
        &-current {
            border-bottom: solid 3px $color-blue;

            .page--item--link {
                color: rgba($color-base-dark-blue, 0.8);
            }
        }

        &:hover a.page--item--link {
            text-decoration: none;
            cursor: pointer;
        }

        + .page--item {
            border-left: none;
        }

        &--link {
            display: block;
            height: 40px;
            padding: 0 3px;
            color: rgba($color-base-dark-blue, 0.5);
            font-size: 0.8em;
        }

        &-prev,
        &-next,
        &-ellipsis {
            font-size: 0.9em;

            .page--item--link {
                padding: 0 5px;
            }
        }

        &-prev,
        &-next {
            width: 75px;
        }

        &-ellipsis,
        &-prev.disabled,
        &-next.disabled {
            background: rgba(142, 155, 164, 0.1);
        }

        &-ellipsis:hover,
        &-prev.disabled:hover,
        &-next.disabled:hover {
            border-bottom: solid 1px rgba($color-base-dark-blue, 0.2);

            .page--item--link {
                color: rgba($color-base-dark-blue, 0.5);
            }
        }
    }
}

@media (max-width: 767px) {
    .pagination {
        display: block;
        font-size: 0;

        .page--item {
            display: inline-block;
            margin-top: -1px;
            margin-left: -2px;
            vertical-align: top;

            + .page--item {
                border-left: solid 1px rgba($color-base-dark-blue, 0.2);
            }

            &.page--item-ellipsis {
                margin-left: -1px;
            }

            &-prev,
            &-next {
                .page--item--link {
                    font-size: 0;

                    &:before {
                        color: rgba($color-base-dark-blue, 0.5);
                        font-size: 1rem;
                        font-family: 'img-icon';
                        line-height: 38px;
                    }
                }
            }

            &-prev .page--item--link:before {
                content: 'o';
            }

            &-next .page--item--link:before {
                content: 'p';
            }

            &--link {
                font-size: 1rem;
            }
        }
    }
}
