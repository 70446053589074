@import '~scss/variables.scss';

.clip-picker {
    display: flex;
    flex-wrap: wrap;

    &--scrollable {
        overflow: hidden;
        overflow-x: auto;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            height: 0; /* Remove scrollbar space */
            background: transparent; /* Optional: just make scrollbar invisible */
        }
    }

    &__single-row {
        display: flex;
        flex-wrap: wrap;

        .clip-picker__item {
            flex-basis: calc(33.3% - (40px / 3));
            margin-right: 10px;

            margin-left: 10px;

            &:first-child,
            &:nth-child(3n + 1) {
                margin-right: 10px;
                margin-left: 0;
            }

            &:nth-child(3n) {
                margin-right: 0;
            }

            &:nth-child(n + 4) {
                margin-top: 20px;
            }
        }
    }

    &__multi-row {
        display: flex;
        flex-wrap: nowrap;

        + .clip-picker__multi-row {
            margin-top: 20px;
        }

        .clip-picker__item {
            flex-basis: 25%;
            flex-shrink: 0;

            + .clip-picker__item {
                margin-left: 20px;
            }
        }
    }

    &__item {
        position: relative;
        margin-bottom: unset;
        cursor: pointer;

        .card.card--vertical {
            width: unset;
            margin: unset;
        }

        .custom-checkbox {
            position: absolute;
            top: 5px;
            left: 5px;
            z-index: 1;

            input {
                border-bottom: unset;

                ~ .custom-checkbox__checkmark {
                    box-sizing: content-box;
                    border: 1px solid rgba($background-color-osiogray, 0.4);
                }
            }
        }

        .selection-card {
            padding: 10px;
            background-color: $background-color-woodsmoke;

            // override
            .button--black {
                background-color: unset;
                &:hover {
                    background-color: $color-blue;
                }

                &:disabled {
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
