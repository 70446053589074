@import '~scss/variables.scss';

.page {
    top: 0;
    width: auto;

    .browse-page {
        .page__header {
            position: sticky;
            top: 0;
            z-index: 20;

            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-top: 20px;
            margin-bottom: 20px;
            padding-top: 35px;
            padding-bottom: 55px;

            background: $color-base-dark;
            border-bottom: 1px solid $color-base-medium;
        }

        .page__title {
            a {
                width: 50px;
                height: 50px;
                margin-right: 5px;
                padding: 9px;

                img {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}
