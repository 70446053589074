@import '~scss/variables.scss';

.edit-clip {
    &__modal {
        max-width: 800px;
        padding-right: 60px;
        padding-left: 60px;

        .form-group + .form-group {
            margin-top: 20px;
        }
        .form-group.clip-title-field {
            label {
                margin-bottom: unset;
            }
        }
    }

    &__thumbnail {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 330px;
        height: 186px;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__thumbnail-wrapper {
        img {
            width: 100%;
        }

        &:hover {
            .edit-clip__select-thumbnail {
                opacity: 1;
            }
        }

        .edit-clip__select-thumbnail {
            opacity: 0;
            transition: opacity linear 0.25s;
        }
    }

    &__thumbnail-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 330px;
        height: 186px;
        border: 2px dashed $background-color-osiogray;
        border-radius: 5px;
    }

    &__select-thumbnail {
        position: relative;
        z-index: 10;
    }
}
