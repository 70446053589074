@import '../../../scss/variables.scss';

.version-panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;

    min-width: 400px;
    padding: 50px;
    color: $color-font-light;

    background-color: rgba($color-black, 0.9);

    transform: translateX(100%);
    transition: transform linear 0.3s;

    &--open {
        transform: translateX(0);
    }

    &__close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &__title {
        color: rgba($color-white, 0.9);
        font-size: 1em;
        font-family: $font-light;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }

    &__section {
        margin-top: 30px;

        &__title {
            padding-bottom: 10px;
            color: rgba($color-white, 0.9);
            font-size: 0.9em;

            font-family: $font-light;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            border-bottom: 1px solid rgba($color-white, 0.9);
        }
    }

    &__content {
        margin-top: 10px;
        margin-bottom: 0;
        padding-left: 0;
        font-size: 0.8em;
        font-family: $font-light;
        letter-spacing: 0.5px;

        li {
            list-style: none;
        }

        .key {
            color: rgba($color-white, 0.9);
            & + .value + .key {
                margin-top: 15px;
            }
        }

        .value {
            color: rgba($color-white, 0.7);
        }
    }
}
