$footerHeight: 75px;

.page-container {
    min-height: 100vh;

    .centering-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100vh;

        &--vertical {
            justify-content: center;
        }
    }

    &--with-footer {
        padding-bottom: $footerHeight;
        .centering-container {
            min-height: calc(100vh - #{$footerHeight});
        }
    }
}
