@import '~scss/variables.scss';

$border-color: $background-color-alto;
$arrow-color: rgba($background-color-osiogray, 0.8);

.channel-manager {
    display: grid;
    grid-template: auto 1fr 110px / 1fr;
    min-height: 100vh;
    background: $background-color-lochmara-003;

    &__confirm-modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 630px;
        height: 275px;
        padding-top: 40px;
        padding-bottom: 40px;
        border-radius: 6px;

        .modal__body {
            color: rgba($color-base-dark-blue, 0.9);
            font-size: 22px;
            font-family: $font-regular;
            line-height: 30px;
        }

        &__confirm-btn {
            width: 215px;
            height: 59px;
            margin-top: 40px;
            font-size: 14px;
            font-family: $font-bold;
            line-height: 30px;
            text-transform: uppercase;
            border: 1px solid $background-color-silver;
            border-radius: 4px;
        }

        &__close-btn {
            position: absolute;
            top: 10px;
            right: 10px;

            svg {
                width: 35px;
                height: 35px;
                fill: $background-color-silver;
            }
        }
    }

    &__modal {
        max-width: 800px;
        overflow-y: auto;

        &.modal {
            background: $color-white;
        }

        .modal__title {
            position: relative;
            color: rgba($color-base-dark-blue, 0.9);
            font-size: 1em;
            font-family: $font-bold;
            letter-spacing: 0.09em;
            text-transform: uppercase;
        }

        .modal__close-btn {
            display: none;
        }
    }

    &__modal--align-right.modal {
        top: unset;
        right: 0;
        left: unset;
        max-width: 800px;
        height: 100vh;
        padding: 30px 70px;
        background: $color-white;
        transform: unset;
    }

    &__scroller-container {
        position: relative;
        padding-top: 30px;
        animation: fadeIn 0.3s;
    }

    &__scroller-inner:focus {
        outline: none;
    }

    &__add-content-back-button {
        position: absolute;
        top: -6px;
        left: -30px;
        width: 40px;
        height: 40px;
        border: 1px solid $border-color;
        border-radius: 50%;

        svg {
            fill: $arrow-color;
        }

        &:focus {
            outline: 1px dotted $color-blue;
        }
    }

    .section-header-text {
        color: rgba($color-base-dark-blue, 0.9);
        font-size: 0.8em;
        font-family: $font-light;
        letter-spacing: 0.09em;
        text-transform: uppercase;
    }
}
