@import '../../../scss/variables';

$parent-class: '.inline-notification';

#{$parent-class} {
    &--dark {
        padding: 20px;
        background-color: $background-color-woodsmoke;

        #{$parent-class}__title {
            color: $color-white;
            font-family: $font-light;
            letter-spacing: 0.7px;
        }

        #{$parent-class}__message {
            margin-bottom: 0;
            color: $color-white;
            font-size: 14px;
            font-family: $font-light;
        }
    }

    &--light {
        &#{$parent-class}--error {
            color: $color-red;
        }

        p {
            margin: 0;
            padding: 20px 0;
            text-align: center;
        }
    }
}
