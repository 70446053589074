@import '~scss/variables.scss';

.channel-manager {
    .pod-details {
        margin-top: 20px;
        padding: 25px 15px;
        background: rgba($color-base-dark-blue, 0.02);
        border-radius: 4px;
        cursor: pointer;
        transition: box-shadow 0.4s, transform 0.2s;
        pointer-events: none;

        &--is-selectable {
            pointer-events: auto;

            &:focus,
            &:hover {
                box-shadow: 0px 0px 19px rgba($background-color-silver, 0.3);
                transform: perspective(1px) scale(1.005);

                .pod-details__duration {
                    text-decoration: underline;
                }
            }

            &:focus {
                outline: 1px dotted $color-blue;
            }
        }

        &--is-default {
            border: 1px solid rgba($color-base-dark-blue, 0.1);
        }

        &--is-selected {
            border: 1px solid rgba($color-blue, 1);
        }

        &--no-ad-breaks {
            padding-bottom: 50px;

            .pod-details__timeline-bar__content {
                border-radius: 4px;
            }
        }

        &__duration {
            padding-bottom: 15px;
            color: rgba($color-base-dark-blue, 0.9);
            font-size: 1em;
            letter-spacing: 0.12em;
            text-align: center;
            text-transform: uppercase;
        }

        &__timeline-bar {
            display: flex;
            height: 34px;

            span {
                position: absolute;
                bottom: -25px;
                width: 100%;
                color: $color-black;
                font-size: 0.7em;
                letter-spacing: 0.09em;
                white-space: nowrap;
                text-align: center;
                text-transform: uppercase;
            }

            &__ads {
                position: relative;
                background: $background-ad-marker-gradient;
                border-radius: 0 4px 4px 0;
            }

            &__content {
                position: relative;
                background: $background-content-marker;
                border-radius: 4px 0px 0px 4px;
            }
        }

        &__ad-breaks {
            margin-top: 40px;
            color: rgba($color-base-dark-blue, 0.5);
            font-size: 0.75em;
            text-align: right;
        }
    }
}
