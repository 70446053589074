@import '~scss/variables.scss';

.custom-select {
    position: relative;
    cursor: pointer;

    &:hover {
        .custom-select__option-list {
            z-index: 1;
            transform: translateY(0px);
            visibility: visible; /* shows sub-menu */
            opacity: 1;
            transition-delay: 0s, 0s, 0.3s;
        }
    }

    &__active-value {
        color: rgba($color-white, 0.5);
        font-weight: 100;
        font-size: 0.75em;
        font-family: $font-light;
        letter-spacing: 1px;
    }

    &__option-list {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: -1;
        max-height: 75vh;
        margin-bottom: 0;
        padding-top: 5px;
        padding-left: 0;
        overflow-y: scroll;
        white-space: nowrap;
        transform: translateY(-10px);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    }

    &__option-item {
        list-style: none;

        button {
            width: 100%;
            padding: 5px 10px;
            color: $color-white;
            font-size: 12px;
            font-family: $font-light;
            text-align: left;
            text-decoration: none;
            background: $color-base-light;
        }
    }

    &__filter {
        outline: none;
    }
}
