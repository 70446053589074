@import '../../../../scss/variables.scss';

.custom-checkbox {
    position: relative;
    display: flex;
    cursor: pointer;

    input {
        position: absolute;
        width: 0;
        height: 0;
        cursor: pointer;
        opacity: 0;
    }
    input ~ .custom-checkbox__checkmark {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        overflow: hidden;
        background-color: $color-white;
        border-radius: 2px;
        cursor: pointer;

        svg {
            transform: translateY(-10px);
            opacity: 0;
            transition: opacity linear 0.15s, transform linear 0.15s;
            fill: #0281c7;
        }
    }
    input:checked ~ .custom-checkbox__checkmark {
        svg {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__label {
        margin-left: 10px;
        line-height: 20px;
        -webkit-font-smoothing: antialiased;
    }
}
