@import '~scss/variables.scss';

.collapse-panel {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    &__title {
        display: flex;
        align-items: center;
        padding: 10px 0;
        color: $text-color-mischka;
        font-weight: bold;
        font-size: 10px;
        font-family: $font-regular;
        line-height: 14px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        cursor: pointer;

        svg {
            width: 10px;
            height: 16px;
            margin-right: 10px;
            transition: transform linear 0.25s;
            fill: #52545d;
        }

        h3 {
            margin-bottom: 0;
        }
    }

    &--hidden {
        .collapse-panel__body {
            height: 0;
            overflow: hidden;
        }
        .collapse-panel__title {
            svg {
                transform: rotate(-90deg);
            }
        }
    }
}
