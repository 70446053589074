@import '~scss/variables.scss';

.channel-manager {
    .content-card {
        display: grid;
        grid-template: auto auto / 160px 1fr;
        transition: outline 0.3s, box-shadow 0.3s;

        &--is-loaded {
            .content-card__thumbnail {
                opacity: 1;
                transition: opacity 0.4s;
            }
        }

        &__thumbnail {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            opacity: 0;

            &:before {
                position: absolute;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(183deg, rgba($color-black, 0) 49%, rgba($color-black, 0.5) 100%);
                content: '';
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        &__meta {
            padding-left: 20px;
            color: rgba($color-base-dark-blue, 0.9);
        }

        &__title {
            display: -webkit-box;
            margin-bottom: 10px;
            overflow: hidden;
            color: rgba($color-base-dark-blue, 0.9);
            font-size: 1em;
            line-height: 1.1em;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        &__duration {
            position: absolute;
            bottom: 8px;
            left: 8px;
            color: $color-white;
            font-size: 0.65em;
            font-family: $font-bold;
            line-height: 1;
            letter-spacing: 0.5px;
        }

        &__description {
            display: -webkit-box;
            height: 32px;
            margin-bottom: 10px;
            overflow: hidden;
            color: rgba($color-base-dark-blue, 0.4);
            font-size: 0.8em;
            font-family: $font-light;
            line-height: 1.2em;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &__bottom-tags {
            display: flex;
        }

        &__bottom-tag {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: 30px;
            padding: 0 15px;
            color: rgba($color-base-dark-blue, 0.9);
            font-size: 0.65em;
            font-family: $font-bold;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            background: rgba($color-base-dark-blue, 0.05);
            border-radius: 4px;

            svg {
                margin-left: 5px;
            }

            .tooltip__message {
                width: 220px;
                font-size: 1.2em;
                white-space: unset;
                text-transform: initial;

                & > div {
                    padding-bottom: 10px;
                    text-align: left;
                }
            }

            + .content-card__bottom-tag {
                margin-left: 10px;
            }
        }

        &__playlists-icon {
            position: relative;
            margin-top: -13px;
            padding-left: 25px;

            &:after {
                top: -2px;
                left: 4px;
            }

            &:before {
                top: 3px;
                left: 0;
            }

            &:after,
            &:before {
                position: absolute;
                width: 15px;
                height: 11px;
                background: rgba($color-blue, 0.3);
                border-radius: 2px;
                content: '';
            }
        }

        &__season-episode-icon {
            position: relative;
            margin-top: -16px;
            padding-left: 20px;

            &:after {
                top: -2px;
                left: -4px;
            }

            &:before {
                top: 3px;
                left: 0;
            }

            &:after,
            &:before {
                position: absolute;
                width: 15px;
                height: 15px;
                background: rgba($color-blue, 0.3);
                border-radius: 15px;
                content: '';
            }
        }
    }
}
