@import '~scss/variables.scss';

.card-grid {
    &__title {
        margin-bottom: 20px;
        font-size: 12px;
        font-family: $font-regular;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        opacity: 0.8;
    }

    &__cards {
        display: flex;
        flex-wrap: wrap;
    }

    &__loading {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 150px;
    }

    & + .card-grid {
        margin-top: 50px;
    }
}
