@import '~scss/variables.scss';

$button-border-color: rgba($background-color-osiogray, 0.2);
$background-color-onahau-faded: rgba(#c7e9ff, 0.5);

// todo needs cleanup
// copied from DA

.date-filter {
    .date-filter-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 44px;
        height: 44px;
        border: 1px solid #ebebeb;
        border-left: 0;
    }

    .custom-dropdown {
        position: relative;
        box-sizing: border-box;
        width: 375px;
        height: 44px;
        margin: 8px 15px;
        color: rgba($color-base-dark-blue, 0.8);
        font-weight: normal;
        font-size: 14px;
        background: $color-white;
        border: rgba($color-base-dark-blue, 0.1) 1px solid;
        border-radius: 2px;
        outline: none;
        transition: all 0.4s ease;

        &.active {
            .dropdown-value {
                svg {
                    transform: rotate(-90deg);
                }
            }
            .dropdown-options {
                max-height: 330px;
                overflow: auto;
            }

            .dropdown {
                display: inline-block;
                opacity: 1;
                pointer-events: auto;
            }
        }

        .dropdown-selector-previous {
            transform: rotate(180deg);
        }

        .dropdown-selector.double-previous {
            position: relative;
            .dropdown-selector-previous {
                position: absolute;
                left: 6px;

                &:first-of-type {
                    left: 0px;
                }
            }
        }

        .dropdown-selector.double-next {
            position: relative;
            .dropdown-selector-next {
                position: absolute;
                right: 6px;

                &:first-of-type {
                    right: 0px;
                }
            }
        }
    }

    .dropdown {
        position: absolute;
        top: 120%;
        right: 0;
        left: -200px;
        z-index: 9999;
        font-weight: normal;
        list-style: none;
        background: $color-white;
        border-radius: inherit;
        opacity: 0;
        transition: all 0.4s ease;
        pointer-events: none;

        &:before {
            position: absolute;
            right: 13px;
            bottom: 100%;
            width: 0;
            height: 0;
            border-color: rgba(0, 118, 192, 0.02) transparent;
            border-style: solid;
            border-width: 0 8px 8px 8px;
            content: '';
        }

        .dropdown-view-wrapper {
            height: 100%;
            overflow: hidden;
            border: 1px solid $button-border-color;
            box-shadow: rgba($color-base-dark-blue, 0.2) 0 8px 16px;
        }

        .dropdown-view-contents {
            display: flex;
            flex-direction: row;
            height: 245px;
        }

        .dropdown-view-title {
            padding: 10px 0;
            font-weight: bold;
            font-size: 1.2em;
            text-align: center;
            text-transform: uppercase;
            border-bottom: rgba($color-base-dark-blue, 0.1) 1px solid;
        }

        .dropdown-view-footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 10px 0;
            border-top: rgba($color-base-dark-blue, 0.1) 1px solid;
        }

        &:before {
            left: 13px;
        }

        &:after {
            left: 15px;
        }

        &.calendar-view {
            // Override base styles
            width: 650px;
            height: 365px !important;

            .datepicker-container {
                position: relative;
                flex-grow: 1;
                height: 100%;
                opacity: 1;
                transition: opacity 0.8s ease 0.2s;
            }
        }

        .datepicker-container {
            width: 515px;
            padding: 10px;
            opacity: 0;

            .back-btn {
                position: absolute;
                margin-top: 10px;
                padding-left: 35px;
                &__arrow {
                    position: absolute;
                    top: -1px;
                    left: 0;
                    width: 25px;
                    height: 25px;
                    border-top: 5px solid;
                    border-left: 5px solid;
                    transform: scale(0.3) rotate(315deg);

                    &:before {
                        display: block;
                        width: 50px;
                        border-top: 5px solid;
                        border-top-width: 5px;
                        border-left: 5px solid;
                        transform: rotate(45deg) translate(0, -2.5px);
                        transform-origin: top left;
                        content: ' ';
                    }
                }
            }

            .daterangepicker {
                top: 15px !important;
                left: auto !important;
                width: 100% !important;
                border: 0px none;
                border-radius: 0px;
                // Override framework styles
                box-shadow: none;

                &:after,
                &:before {
                    content: none;
                }

                .drp-buttons {
                    display: none;
                }

                .ranges {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                }
            }
        }

        .dropdown-options {
            width: 150px;
            margin: 0;
            padding: 0;
            border-right: rgba($color-base-dark-blue, 0.1) 1px solid;
            opacity: 1;
            transition: opacity 0.8s ease 0.2s, transform 0.5s;

            .dropdown-option {
                display: block;
                padding: 12px 15px;

                color: rgba($color-base-dark-blue, 0.8);
                text-decoration: none;
                border: 0px none;
                border-bottom: rgba($color-base-dark-blue, 0.1) 1px solid;
                cursor: pointer;
                transition: all 0.4s ease;

                &:hover {
                    background-color: $background-color-onahau-faded;
                }

                &.selected {
                    font-weight: bold;
                    background-color: $background-color-onahau-faded;
                }

                .date-label {
                    float: right;
                    color: rgba($color-black, 0.4);
                }
            }

            li {
                list-style: none;
            }
        }
    }

    .dropdown-options {
        max-height: 0;
        transition: max-height 0.3s ease;
    }

    .dropdown-value {
        position: relative;
        width: 100%;
        height: 40px;
        padding: 0 30px 0 10px;
        font-size: 1em;
        font-family: $font-bold;
        line-height: 38px;
        letter-spacing: 0.02em;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;

        svg {
            position: absolute;
            top: 10px;
            right: 10px;
            transform: rotate(90deg);
            transition: transform 0.2s;
            fill: $background-color-silver;
        }
    }

    .dropdown-selector {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 44px;
        height: 44px;
        color: #c4c4c4;
        border: 1px solid #ebebeb;
        cursor: pointer;
        fill: #c4c4c4;
    }
}
