@import '~scss/variables.scss';

$light-border-color: rgba($color-base-dark-blue, 0.1);
$medium-border-color: rgba($color-base-dark-blue, 0.6);
$text-color: rgba($color-base-dark-blue, 0.9);

.channel-manager {
    .search-result {
        display: flex;
        gap: 25px;
        align-items: center;
        height: 130px;
        padding: 0 23px;
        cursor: pointer;
        transition: transform 0.3s;

        &:not(:first-child) {
            margin-top: 20px;
        }

        &:focus,
        &:hover {
            transform: scale(1.01);

            .content-card {
                border: 1px solid $light-border-color;
                box-shadow: 0 8px 16px 8px $light-border-color;

                &__preview-pod-button {
                    opacity: 1;
                }
            }

            .search-result__add-button {
                border: 1px solid $light-border-color;
                box-shadow: 0 8px 16px 8px $light-border-color;
                opacity: 1;
            }
        }

        &:focus {
            outline: none;
        }

        &:active {
            transform: scale(0.9);
            transition: transform 0.3s;
        }

        &--just-added {
            &:hover,
            &:focus {
                .content-card {
                    border: 1px solid $color-blue;
                }
            }
        }

        &__add-button {
            display: grid;
            width: 30px;
            height: 30px;
            background-color: $color-blue;
            border-radius: 50px;
            opacity: 0;
            transition: opacity 0.3s, box-shadow 0.3s;
            place-content: center;

            svg {
                width: 15px;
                height: 15px;
                fill: $color-white;
            }
        }

        .content-card {
            position: relative;
            flex: 1;
            max-width: 645px;
            padding: 15px;
            border: 1px solid transparent;
            transition: box-shadow 0.5s, border 0.5s;

            &__preview-pod-button {
                position: absolute;
                right: 15px;
                bottom: 15px;
                display: grid;
                grid-template-columns: auto auto;
                height: 30px;
                padding: 0 15px;
                color: $text-color;
                font-size: 0.65em;
                font-family: $font-bold;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                border: 1px solid $light-border-color;
                border-radius: 4px;
                opacity: 0;
                transition: opacity 0.3s, border-color 0.3s;
                place-items: center;

                &:hover {
                    border-color: $medium-border-color;
                }

                svg {
                    margin-right: 5px;
                    fill: $medium-border-color;
                }
            }
        }
    }
}
