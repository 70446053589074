@import '../../../scss/variables.scss';

$spinner-size: 50px;
$border-size: 3px;

$spinner-size-small: 16px;
$border-size-small: 2px;

.loading-spinner {
    position: relative;
    display: inline-block;
    width: $spinner-size;
    height: $spinner-size;

    .static-ring {
        position: absolute;
        display: block;
        box-sizing: border-box;
        width: $spinner-size;
        height: $spinner-size;
        border: $border-size solid $color-base-light;
        border-radius: 50%;
    }

    .animate {
        position: absolute;
        display: block;
        box-sizing: border-box;
        width: $spinner-size;
        height: $spinner-size;
        border: $border-size solid;
        border-color: $color-blue transparent transparent transparent;
        border-radius: 50%;
        animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }
        &:nth-child(2) {
            animation-delay: -0.3s;
        }
        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }

    &--small {
        width: $spinner-size-small;
        height: $spinner-size-small;

        .static-ring {
            width: $spinner-size-small;
            height: $spinner-size-small;
            border: $border-size-small solid $color-base-light;
        }

        .animate {
            width: $spinner-size-small;
            height: $spinner-size-small;
            border: $border-size-small solid $color-blue;
            border-color: $color-blue transparent transparent transparent;
        }
    }
}

@keyframes loading-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
