@import '../../../scss/variables';

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1001;
    width: 100%;
    max-width: 500px;
    padding: 40px;
    background-color: $background-color-cinder;
    box-shadow: 0 2px 4px 0 rgba($color-black, 0.5);
    transform: translate(-50%, -50%);

    &__title {
        color: $color-white;
        font-size: 24px;
        font-family: $font-light;
        line-height: 28px;

        &--center {
            text-align: center;
        }
    }

    &__title + &__body {
        margin-top: 30px;
    }

    &__body {
        &:first-child {
            margin-top: 0;
        }

        &--center {
            text-align: center;
        }
    }

    &__close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10;
        padding: 2px 5px;
        background-color: $color-base-dark;
        border-radius: 2px 2px;

        svg {
            width: 20px;
            height: 20px;
            transition: fill linear 0.3s;
            fill: $color-white;
            &:hover {
                fill: $color-white;
            }
        }
    }
}

.modal__loading-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1001;
    transform: translate(-50%, -50%);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba($color-black, 0.8);
}
