@import '../../../scss/variables.scss';

.feedback-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    box-sizing: content-box;
    padding: 20px !important;
    transform: translate(-50%, -50%);

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        margin: 0 auto;
        background-color: $color-blue;
        border-radius: 25px;
        svg {
            fill: $color-white;
        }
    }
    .modal__title {
        margin-top: 20px;
    }
    p {
        margin-top: 15px;
        margin-bottom: 0;
        color: $color-disabled-text;
    }
    .button {
        margin-top: 40px;
    }
}
