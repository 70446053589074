.page {
    width: 100%;
    transform: translateY(0);
    opacity: 1;
    transition: transform ease-in-out 0.25s, opacity ease-in-out 0.25s;

    &-appear,
    &-enter {
        transform: translateY(100px);
        opacity: 0;
    }

    &-exit {
        transform: translateY(-100px);
        opacity: 0;
    }

    &-enter-active {
        position: absolute;
    }
}
