@import '~scss/variables.scss';
$main-color: rgba($color-base-dark-blue, 0.9);
$border-color: $background-color-alto;
$button-border-color: rgba($background-color-osiogray, 0.2);
$arrow-color: rgba($background-color-osiogray, 0.8);
$small-screen-height: 1080px;
$medium-screen-width: 1260px;
$large-screen-width: 1440px;
$color-error: #e74a51;

.channel-manager {
    .channel-details {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: content-box;
        height: 90px;
        padding: calc(10px + 1vh) calc(20px + 1vw);
        color: $main-color;
        background: $color-white;
        opacity: 0.5;
        transition: opacity 0.4s;

        &:hover {
            opacity: 1;
        }

        @media only screen and (min-height: #{$small-screen-height}) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        &__back-button {
            width: 40px;
            height: 40px;
            border: 1px solid $border-color;
            border-radius: 50%;

            svg {
                fill: $arrow-color;
            }
        }

        &__controls {
            &:hover {
                .channel-details__settings-menu {
                    opacity: 1;
                }
            }

            .channel-info__value {
                display: flex;
                align-items: center;
            }
        }

        &__edit-menu,
        &__settings-menu,
        &__schedule-button {
            margin-left: 10px;
        }

        &__settings-menu {
            opacity: 0;
            transition: opacity 0.4s;
        }

        &__schedule-button,
        &__edit-button,
        &__add-content-button {
            height: 61px;
            padding: 0 20px;
            font-size: 1em;
            font-family: $font-bold;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            border: 1px solid $button-border-color;
            border-radius: 4px;

            &:disabled {
                opacity: 0.5;
            }
        }

        &__schedule-button svg {
            width: 25px;
            height: 25px;
            fill: $main-color;
        }

        &__add-content-button {
            white-space: nowrap;
        }

        &__edit-button {
            svg {
                margin-left: 10px;
                transform: rotate(90deg);
                transition: transform 0.2s;
                fill: $main-color;
            }
        }

        &__edit-dropdown,
        &__tray-menu {
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: space-around;
            padding: 15px;

            svg {
                width: 28px;
                height: 28px;
            }
        }

        &__edit-menu {
            &.dropdown-menu--open .channel-details__edit-button {
                svg {
                    transform: rotate(-90deg);
                }
            }
        }

        &__batch-button {
            box-sizing: border-box;
            width: 60px;
            height: 60px;
            background: rgba($color-base-dark-blue, 0.1);
            border: 1px solid $button-border-color;
            border-radius: 4px;
            transition: background-color 0.3s;

            &--is-active {
                background-color: rgba($color-base-dark-blue, 0.9);
            }
        }

        &__channel-info-wrapper {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: space-between;
        }

        .channel-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 0 60px;

            @media only screen and (max-width: #{$large-screen-width}) {
                padding: 0 50px;
            }

            &--is-row {
                flex-direction: row;
            }

            &__divider {
                width: 1px;
                height: 50px;
                border-right: 1px solid $border-color;
            }

            &__icon {
                margin-right: 20px;

                img {
                    border-radius: 4px;
                }
            }

            &__label {
                color: $text-color-santas-gray;
                font-size: 0.8em;
                font-family: $font-light;
                letter-spacing: 0.08em;
                text-align: center;
                text-transform: uppercase;
            }

            &__value {
                position: relative;
                color: rgba($color-base-dark-blue, 0.9);
                font-size: 1.3em;
                font-family: $font-regular;
                letter-spacing: 0.02em;
                text-align: center;
            }
        }

        @media only screen and (max-width: #{$medium-screen-width}) {
            .channel-details {
                &__videos-count,
                &__controls + .channel-info__divider {
                    display: none;
                }
            }
        }
    }

    &__channel-admin-view-modal,
    &__channel-settings-modal {
        &__body {
            overflow: auto;
        }

        .form-field-row {
            justify-content: space-between;

            &__input {
                position: relative;
                display: flex;
                align-items: center;
                width: 350px;
                height: 65px;
                padding: 10px;
                background: white;
                border: 1px solid rgba(208, 208, 208, 0.5);
                border-radius: 4px;

                &.error {
                    border: 1px solid rgba($color-error, 0.5) !important;
                }
            }

            &__error {
                color: $color-error;
                text-align: center;
            }

            &__helptext {
                margin-top: 5px;
                color: $text-color-midnight;
                font-size: 12px;
            }

            &__file-input {
                position: relative;
                width: 350px;
                padding: 10px;
                background: white;
                border: 1px dashed $border-color;
                border-radius: 4px;

                &__select {
                    cursor: pointer;
                }

                input {
                    display: none;
                }

                .loading-spinner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($color-white, 0.8);
                }
            }

            &__file-input-container {
                display: flex;
                flex-direction: column;
            }

            &__file-preview {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 350px;
                padding: 10px;
                background: white;
                border: 1px dashed $border-color;
                border-radius: 4px;

                &__image-container {
                    display: flex;
                    align-items: center;
                    width: 48px;
                    height: 48px;

                    img {
                        max-width: 48px;
                        max-height: 48px;
                    }
                }

                &__file-meta h4 {
                    width: 175px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &__file-delete {
                    cursor: pointer;
                    opacity: 1;
                    transition: 0.3s all;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    &__channel-settings-modal,
    &__channel-delete-modal,
    &__channel-admin-view-modal {
        display: grid;
        grid-template-rows: 100px auto;
        // the important was introduced becuase there is a descrepancy on how the css is loaded from development and production
        // where the rules and specificity are not the same causing untoward issues with rendering. https://dicetech.atlassian.net/browse/RSBO-1788
        width: 840px !important;
        max-width: 840px !important;
        padding: 0 !important;
        border-radius: 6px;

        .highlighted {
            font-weight: bold;
            font-size: 1.2em;
        }

        &__logo {
            width: 44px;
        }

        &__title {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            padding: 0 50px;
            font-size: 1.5em;
            font-family: $font-regular;
            letter-spacing: 0.02em;

            img {
                margin-right: 10px;
                border-radius: 4px;
            }
        }

        &__body {
            position: relative;
            min-height: 200px;
            padding: 50px 50px;
            background-color: $background-color-lochmara-003;

            .form-field-row {
                display: grid;
                grid-template-columns: 1fr 2fr;

                align-items: center;
                padding: 5px 0;

                label {
                    margin: 0 20px 0 0;
                    color: rgba($color-base-dark-blue, 0.9);
                    font-size: 1em;
                    font-family: $font-bold;
                    letter-spacing: 0.02em;
                    text-transform: uppercase;
                }

                select {
                    width: 50%;
                }
            }
        }

        &__footer {
            padding: 15px 50px;
            background-color: $color-white;

            &--center {
                display: flex;
                justify-content: center;
                padding: 15px 50px;
            }
        }

        // why is this different to action button
        &__confirm-btn {
            height: 61px;
            padding: 0 10px;
            color: $color-white;
            font-size: 15px;
            font-family: $font-bold;
            line-height: 18px;
            text-transform: uppercase;
            background: $color-blue;
            border-radius: 4px;
            transition: 0.3s background-color;

            &:not(:only-child) {
                margin: 0 15px;
            }

            &:hover {
                background-color: rgba($color-blue, 0.9);
            }

            &:disabled {
                background: rgba($color-base-dark-blue, 0.1);
            }
        }

        &__action-btn {
            height: 61px;
            padding: 0 10px;
            color: $color-white;
            font-size: 15px;
            font-family: $font-bold;
            line-height: 18px;
            text-transform: uppercase;
            background: $color-blue;
            border-radius: 4px;

            &:not(:only-child) {
                margin: 0 15px;
            }

            &:disabled {
                background: rgba($color-base-dark-blue, 0.1);
            }
        }

        &__close-btn {
            position: absolute;
            top: 10px;
            right: 10px;

            svg {
                width: 35px;
                height: 35px;
                fill: $background-color-silver;
            }
        }
    }

    &__channel-admin-view-modal,
    &__channel-delete-modal {
        &__content {
            padding: 50px;
        }

        &__confirm-action,
        &__set-event-id {
            display: flex;
            flex-direction: column;
            gap: 16px;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: $background-color-polar;
        }

        &__confirmation,
        &__dge-event-id {
            width: 100%;
            margin: 40px 0;
        }

        .loading-spinner {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: rgba($color-white, 0.7);
        }

        .status-dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 10px;
            background: $background-color-polar;
            border-radius: 50%;
            &.provisioning {
                border: 1px solid rgba($color-info, 0.5);
            }
            &.ready {
                border: 1px solid rgba($color-success, 0.5);
            }
            &.live {
                background: rgba($color-success, 0.5);
            }
            &.terminated {
                background: rgba($color-danger, 0.5);
            }
            &.stopped {
                border: 1px solid rgba($color-danger, 0.5);
            }
        }

        table {
            width: 100%;
            &,
            & * {
                transition: all 0.3s;
            }

            thead th {
                padding: 0 5px 10px;
                color: rgba($color-base-dark-blue, 0.45);
                font-size: 0.7em;
                letter-spacing: 1px;
                text-transform: uppercase;
                border-bottom: 1px solid $border-color;
            }

            tbody tr {
                &:hover {
                    background-color: $color-white;

                    td:first-child {
                        box-shadow: inset 0px 1px 0px 0px transparent, inset 0px -1px 0px 0px transparent, inset 2px 0 0 $color-blue;
                    }
                }
                &:focus {
                    outline: none;
                }

                td {
                    padding: 15px 5px;
                    color: rgba($color-base-dark-blue, 0.9);
                    font-size: 1.1em;
                    border-bottom: 1px solid $border-color;
                }
            }
        }

        &__refresh-btn {
            height: 61px;
            margin-left: 20px;
            padding: 0 10px;
            color: $color-white;
            font-size: 15px;
            font-family: $font-bold;
            line-height: 18px;
            text-transform: uppercase;
            background: $color-blue;
            border-radius: 4px;

            &:disabled {
                background: rgba($color-base-dark-blue, 0.1);
            }
        }
    }
}
