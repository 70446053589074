@import '~scss/variables.scss';

.pagination {
    &__btn-loadmore {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
        padding: 10px;
        font-size: 0.8em;
        font-family: $font-light;
        text-transform: uppercase;
        background-color: $background-color-woodsmoke;
        transition: background-color linear $background-color-transition-timing;

        &:hover {
            background-color: $background-color-brightgray;
        }
    }
}
