@import '~scss/variables.scss';

$border-color: $background-color-alto;
$svg-fill-color: rgba($text-color-santas-gray, 0.9);

.channel-manager {
    &__pod-management-modal {
        position: absolute;

        &.pod-management-modal {
            &-enter {
                .modal {
                    transform: translateX(100%);
                }
                .overlay {
                    opacity: 0.5;
                }
            }

            &-enter-active {
                .modal {
                    transform: translateX(0%);
                    transition: transform 0.5s;
                }
                .overlay {
                    opacity: 1;
                    transition: opacity 0.3s;
                }
            }

            &-exit-active {
                .modal {
                    transform: translateX(100%);
                    transition: transform 0.3s;
                }
                .overlay {
                    opacity: 0;
                    transition: opacity 0.3s;
                }
            }
        }
    }

    .pod-management-modal__content-search {
        .modal {
            display: flex;
            flex-direction: column;
            padding: 20px 0 0;

            &__body,
            .content-page__no-content {
                flex: 1;
            }
        }

        .content-search {
            display: flex;
            flex-direction: column;
            height: 100%;

            &__input-wrapper {
                position: relative;
                box-sizing: border-box;
                height: 67px;
                margin: 0 75px;
                background: $color-white;
                border: 1px solid $border-color;
                border-radius: 4px;

                input {
                    width: 100%;
                    height: 100%;
                    padding-right: 100px;
                    padding-left: 70px;
                    font-size: 1.05em;
                    border: 0px none;
                }

                svg {
                    position: absolute;
                    width: 20px;
                    height: 100%;
                    margin-left: 25px;
                    fill: $svg-fill-color;
                }
            }

            &__results-text {
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                height: 100%;
                padding-right: 20px;
                padding-left: 10px;
                color: rgba($color-base-dark-blue, 0.5);
                font-size: 0.7em;
                text-transform: uppercase;
            }

            &__results-wrapper {
                flex: 1;
                padding-top: 40px;
                padding-bottom: 60px;
                overflow-x: hidden;
            }

            &__selected-content-list {
                &-enter {
                    transform: translateY(100px);
                }
                &-enter-active {
                    transform: translateY(0);
                    opacity: 1;
                }
                &-exit-active {
                    transform: translateY(250px);
                }
                &-enter-active,
                &-exit-active {
                    transition: transform 0.5s;
                }
            }
        }
    }
}
