.time-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 44px;
    margin: 0 15px;
    border: 1px solid #ebebeb;

    &__filter {
        box-sizing: border-box;
        background-color: #ffffff;
        border: 1px solid rgba(216, 216, 216, 0.1);
        border-radius: 2px;
        cursor: pointer;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 44px;
        height: 44px;
        border: 1px solid #ebebeb;
        border-right: 0;
    }
}
