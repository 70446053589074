@import '../../../scss/variables.scss';

.profile-menu-wrapper {
    position: relative;
    display: flex;
    align-items: center;

    .username {
        margin-bottom: 0;
        color: rgba($color-white, 0.5);
        font-weight: 100;
        font-size: 0.75em;
        font-family: $font-light;
        letter-spacing: 1px;
    }

    .profile-menu {
        display: flex;
        margin-left: 10px;
        cursor: pointer;
    }

    .nav-icon-wrapper {
        display: flex;
        align-items: center;

        .nav-icon {
            display: flex;
            align-items: center;
            justify-content: center;

            &.user-icon {
                width: 26px;
                height: 26px;
                padding: 5px;
                overflow: hidden;
                border: 1px solid rgba($color-white, 0.5);
                border-radius: 50%;

                svg {
                    flex-shrink: 0;
                    width: 22px;
                    height: 22px;
                    margin-top: 5px;
                }
            }

            &.settings-icon {
                svg * {
                    stroke: rgba($color-white, 0.5);
                }
            }

            svg {
                width: 26px;
                height: 26px;
                fill: rgba($color-white, 0.5);
            }
        }

        .nav-icon-arrow {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 5px;
            border: 5px solid transparent;
            border-top-color: $color-disabled-text;
            border-bottom-width: 0;
        }
    }

    .custom-select {
        margin-right: 10px;
    }

    .create-realm {
        display: flex;
        align-items: center;
        margin-right: 5px;
        color: rgba($color-white, 0.5);
        font-weight: 100;
        font-size: 0.75em;
        font-family: 'Roboto-Light', arial;
        letter-spacing: 1px;
        text-decoration: none;
        cursor: pointer;
        transition: color 0.4s;

        &:hover {
            color: rgba($color-white, 0.8);
        }
    }

    .settings__time-format {
        &-label {
            padding-bottom: 5px;
            font-size: 12px;
            font-family: $font-light;
            text-transform: uppercase;
        }

        .dropdown-menu__dropdown {
            width: 150px;
            padding: 15px 20px;
        }
    }
}
