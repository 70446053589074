@import '~scss/variables.scss';

.clip-list {
    .clip-item {
        margin-top: 10px;
        &:first-child {
            margin-top: 0;
        }
    }
}

.clip-item {
    position: relative;
    display: flex;
    background-color: $background-color-woodsmoke;
    transition: background-color linear 0.25s;

    &:hover {
        z-index: 10;
        cursor: pointer;
        transition-delay: 0s;
        .clip-item__menu {
            opacity: 1;
            transition-delay: 0s;
        }
    }

    &--active {
        background-color: rgba($background-color-lochmara, 0.6);
    }

    &--preview {
        background-color: rgba($color-white, 0.4);
    }

    &--invalid {
        background-color: rgba($color-danger, 0.4);
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__thumbnail {
        position: relative;
        flex-shrink: 0;
        width: 140px;

        &:before {
            position: relative;
            display: block;
            padding-top: (9 / 16) * 100%;
            content: '';
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }

        .loading-spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__content {
        position: relative;
        padding: 10px;
    }

    &__title {
        padding-right: 20px;
        overflow: hidden;
        color: $text-color-mischka;
        font-size: 14px;
        font-family: $font-light;

        white-space: nowrap;
        text-overflow: ellipsis;
        @supports (-webkit-line-clamp: 2) {
            display: -webkit-box;
            overflow: hidden;
            white-space: unset;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }

    &__duration {
        display: flex;
        align-items: center;
        margin-top: 5px;
        color: $text-color-mischka;
        font-size: 13px;
        font-family: $font-light;

        svg {
            width: 14px;
            height: 14px;
            margin-right: 5px;
            fill: currentColor;
        }
    }

    &__time {
        margin-top: 5px;
        color: $text-color-mischka;
        font-size: 12px;
        font-family: $font-light;
    }

    &__status {
        position: absolute;
        top: 10px;
        right: 10px;

        svg {
            width: 16px;
            height: 16px;
            transition: fill linear 0.25s;
            fill: $color-danger;
        }
    }

    &__actions {
        position: absolute;
        right: 10px;
        bottom: 10px;
        display: flex;
        align-items: center;

        .list-item {
            margin-right: 8px !important; // overrides default list-item margin
            &.clip-item__export-btn {
                // odd shaped icon required spacing adjustment
                margin-right: 5px !important;
            }
            &:last-child {
                margin-right: 0 !important; // overrides the above rule for the last item
            }
        }

        .tooltip__message.tooltip-top {
            // center tooltip within the 16px icon
            margin-left: 0;
        }

        a,
        button {
            display: flex;
            align-items: center;
            color: rgba($color-white, 0.75);

            svg {
                width: 16px;
                height: 16px;
                transition: fill linear 0.25s;
                fill: rgba($color-white, 0.75);

                &.export-icon {
                    width: 18px;
                    height: 18px;
                }

                &.preview-icon {
                    width: 18px;
                    height: 18px;
                }
            }

            &:hover {
                color: $color-white;
                text-decoration: none;
                cursor: pointer;

                svg {
                    fill: $color-white;
                }
            }
        }

        .card__actions__text {
            margin-left: 5px;
        }
    }
}
