@import '~scss/variables.scss';
$color-gallery: #ebebeb;

.zoom {
    display: flex;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        box-sizing: border-box;
        width: 200px;
        height: 44px;
        margin: 0 0 0 30px;
        padding: 0 5px;
        border: 1px solid $color-gallery;
        border-right: none;

        input {
            width: 100%;
        }

        &__labels {
            display: flex;
            justify-content: space-evenly;
            width: calc(100% + 5px);
        }

        &__label {
            position: relative;
            width: 22px;
            color: $text-color-zorba;
            font-size: 0.75em;
            text-align: center;

            &::before {
                position: absolute;
                top: -15px;
                right: 50%;
                left: 50%;
                z-index: -1;
                display: block;
                width: 1px;
                height: 15px;
                margin: auto;
                background-color: $text-color-zorba;
                content: ' ';
            }

            &--selected {
                color: $text-color-midnight;
            }
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 44px;
        height: 44px;
        border: 1px solid $color-gallery;
        cursor: pointer;

        &:hover svg {
            opacity: 0.5;
            transition: all 0.3s;
        }
    }
}

.zoom-value {
    width: 100px;
    color: rgba($color-base-dark-blue, 0.9);
    font-size: 1em;
    font-family: $font-bold;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;
}
