@import '~scss/variables.scss';
$color-gallery: #ebebeb;

.time-zone {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 44px;
    margin: 0 15px;
    border: 1px solid $color-gallery;

    &__filter {
        box-sizing: border-box;
        background-color: #ffffff;
        border: 1px solid rgba($color-gallery, 0.1);
        border-radius: 2px;
        cursor: pointer;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 44px;
        height: 44px;
        border: 1px solid $color-gallery;
        border-right: 0;
        cursor: pointer;

        &:hover svg {
            opacity: 0.5;
            transition: all 0.3s;
        }
    }
}
