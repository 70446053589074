@import '../../../scss/variables.scss';
@import '~@dicetechnology/doris/src/scss/stats';

$header-height: 55px;
$timeline-controls-height: 40px;
$timeline-grid-height: 150px;
$timeline-height: $timeline-controls-height + $timeline-grid-height;

// todo: make `stats-modal`'s wrapper relative to reduce height based from the content below the video player.
// todo: will be redundant when we can supply a mount node the stats-modal.
.body {
    position: relative;
}

// stats panel overrides
.stats-modal {
    top: 65px;
    bottom: 320px;
    left: 20px;

    .stats-close-icon {
        display: none;
    }
}

%fullVideo {
    width: 100%;
    height: 100%;
}

.editor {
    width: 100%;
    padding: 0 10px;
    overflow: hidden;

    h2 {
        margin: 0;
        color: rgba($color-white, 0.9);
        font-size: 0.7em;
        font-family: $font-light;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }

    &__header {
        display: flex;
        align-items: center;
        width: calc(100% - 200px);
        margin-top: 10px;
        padding: 10px;
        animation: transitionTop 1s;

        .left-section,
        .right-section {
            display: flex;
        }

        .get-started-btn {
            margin-right: 10px;
            background: $color-base-medium;
            border-radius: 5px;

            &:hover {
                background: $color-base-light;
                cursor: pointer;
            }

            .get-started-icon {
                display: block;
                width: 30px;
                height: 30px;
                background: url('../../../assets/images/get-started-btn.png');
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        .video-title {
            margin-bottom: 0;
            font-size: 0.8em;
            font-family: $font-light;
            letter-spacing: 0.8px;
            text-transform: none;
        }

        .live-tag {
            width: 38px;
            height: 18px;
            margin-left: 10px;
            font-weight: 100;
            font-size: 0.6em;
            font-family: $font-light;
            line-height: 18px;
            letter-spacing: 1.2px;
            text-align: center;
            text-transform: uppercase;
            background-color: $color-red;
            border-radius: 2px;
        }
    }

    &__video-panel {
        display: flex;

        // max video height for video at 1920/1080
        max-height: 694px;
        border-bottom: 3px solid $color-black;
        animation: transitionOpacity 2s;

        .video-preview {
            position: relative;
            flex-basis: 65%;
            margin-right: 20px;

            .video-preview-player {
                height: 100%;
                video {
                    @extend %fullVideo;
                }
            }
        }

        .video-placeholder-img {
            opacity: 1;
            transition: opacity 0.2s ease-in;

            &.loaded {
                opacity: 0;
            }
        }

        .video-sidepanel {
            display: flex;
            flex-basis: 34%;
            flex-direction: column;
            flex-grow: 1;
            overflow: hidden;
            overflow-y: auto;
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                width: 0; /* Remove scrollbar space */
                background: transparent; /* Optional: just make scrollbar invisible */
            }
        }
    }
}

.timeline-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $timeline-controls-height;
    border-bottom: 1px solid $color-base-light;

    .inline-list {
        .list-item {
            margin-right: 10px;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 25px;
        background: $color-base-medium;
        border: 1px solid transparent;
        border-radius: 5px;
        transition: background-color linear 0.25s;

        &:disabled {
            pointer-events: none;
            svg {
                opacity: 0.5;
            }
        }

        &:hover:not(:disabled) {
            background-color: $color-base-light;
        }

        &--active {
            background-color: $color-blue;
            &:hover:not(:disabled) {
                background-color: rgba($color-blue, 0.8);
            }
        }

        svg {
            width: 15px;
            transition: opacity linear 0.25s;
            fill: $color-white;
        }
    }

    .btn-live {
        display: flex;
        align-items: center;
        width: auto;
        padding: 0 5px;
        font-size: 10px;
        text-transform: uppercase;

        svg {
            margin-right: 5px;
        }

        &--is-live {
            color: $color-red;
            border: 1px solid $color-red;
            svg {
                margin-right: 5px;
                fill: $color-red;
            }
        }

        &:disabled {
            cursor: text;
        }
    }

    .fps-text {
        color: $color-disabled-text;
        font-size: 0.7em;
    }

    button:hover {
        cursor: pointer;
    }
}

.zoom-slider {
    display: flex;
    align-items: center;

    &__label {
        color: #848790;
        font-size: 0.7em;
        text-transform: uppercase;
    }

    svg {
        width: 14px;
        margin: 0 10px;
        fill: $color-disabled-text;
    }

    button {
        &:hover {
            svg {
                fill: $color-blue;
            }
        }
    }

    input {
        width: 130px;
        -webkit-appearance: none;

        &:hover {
            cursor: pointer;
        }

        &::-webkit-slider-runnable-track {
            height: 3px;
            background: $color-base-light;
            border: none;
        }

        &::-webkit-slider-thumb {
            position: relative;
            top: -5px;
            width: 13px;
            height: 13px;
            background: $color-white;
            border-radius: 50%;
            -webkit-appearance: none;
        }

        &:focus {
            outline: none;
        }
    }
}

.modal-animation {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s ease-in;

    &--open {
        visibility: visible;
        opacity: 1;
    }
}

.video {
    position: relative;
    &__thumbnail {
        position: absolute;
        top: 0;
        width: 100%;
    }
    video {
        width: 100%;
    }
}

@keyframes transitionOpacity {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes transitionBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes transitionTop {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}
