@import '../../../scss/variables.scss';

// Page level variables
$content-max-width: 1920px;
$page-spacing: 50px;
$header-height: 200px;
$filters-height: 70px;
$border-color: $background-color-gallery;

.content-page {
    display: grid;
    grid-template-rows: $header-height $filters-height 1fr;
    grid-template-columns: 1fr auto;
    min-height: 100vh;
    background: $background-color-lochmara-003;

    &__header {
        position: relative;
        grid-column: 1 / span 2;
        height: $header-height;
    }

    &__header-nav-logo {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
        width: 50px;
        height: 50px;
        padding: 9px;

        img {
            width: 32px;
            height: 32px;
        }
    }

    &__header-inner {
        height: 100%;
        // TODO for demo
        background-image: url('https://vod-dev.diceplatform.com/3302729110716b94eef6e9da473d1703.jpg');
        background-position: center center;
        background-size: cover;

        h1 {
            position: absolute;
            top: 50%;
            left: $page-spacing;
            color: $color-white;
            font-weight: 300;
            font-size: 2.1em;
            font-family: $font-light;
            letter-spacing: -0.5px;
            transform: translateY(-50%);
            -webkit-font-smoothing: antialiased;
        }
    }

    &__header-cover {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color-base-dark-blue, 0.85);
    }

    .search-field-container {
        padding-top: 5px;
        background: $color-white;
    }

    &__tab-section {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0 $page-spacing;
        list-style-type: none;
        background: $color-white;
        border-bottom: 1px solid $border-color;

        button {
            display: flex;
            align-items: center;
            margin-right: 20px;
            padding: 21px 10px;
            color: rgba($color-base-dark-blue, 0.45);
            font-size: 1em;
            border-bottom: 2px solid $color-white;

            &:hover {
                color: rgba($color-base-dark-blue, 0.9);
                text-decoration: none;
                transition: color 0.3s;
            }

            &.active-tab {
                color: rgba($color-base-dark-blue, 0.9);
                font-family: $font-bold;
                border-color: $color-blue;

                .red-dot {
                    background: $color-red;
                }
            }

            .red-dot {
                width: 8px;
                height: 8px;
                margin-right: 10px;
                background: rgba($color-red, 0.5);
                border-radius: 50%;
            }
        }
    }

    &__no-content {
        display: flex;
        flex-direction: column;
        grid-column: 1 / span 2;
        align-items: center;
        padding: 30px 0;

        .loading-spinner {
            margin-bottom: 10px;
        }
    }

    &__content {
        grid-column: 1 / span 2;
        width: 100%;
        max-width: $content-max-width;
        margin: 0 auto;
        padding: 0 $page-spacing 30px;

        &--hide {
            display: none;
        }

        &-table {
            width: 100%;
            &,
            & * {
                transition: all 0.3s;
            }

            .channel-icon {
                padding-right: 30px;

                img {
                    border-radius: 4px;
                }
            }

            thead th {
                padding: 30px 5px 10px;
                color: rgba($color-base-dark-blue, 0.45);
                font-size: 0.7em;
                letter-spacing: 1px;
                text-transform: uppercase;
                border-bottom: 1px solid $border-color;

                &:first-child {
                    padding-left: 105px;
                }
            }

            tbody tr {
                &:hover {
                    background-color: $color-white;
                    cursor: pointer;

                    td:first-child {
                        box-shadow: inset 0px 1px 0px 0px transparent, inset 0px -1px 0px 0px transparent, inset 2px 0 0 $color-blue;
                    }

                    .channel-details__edit-menu {
                        opacity: 1;
                    }
                }
                &:focus {
                    outline: none;
                }

                td {
                    padding: 30px 5px;
                    color: rgba($color-base-dark-blue, 0.9);
                    font-size: 1.1em;
                    border-bottom: 1px solid $border-color;

                    &:first-child {
                        padding-left: 30px;
                    }
                }

                .channel-details__edit-menu {
                    opacity: 0;
                }
            }
        }

        .pagination {
            margin-top: 30px;
        }
    }

    .search-container {
        display: flex;
        align-items: center;
        background: $color-white;
        border-bottom: 1px solid $border-color;

        &.search-is-active {
            .custom-dropdown {
                opacity: 1;
            }
        }

        .custom-dropdown {
            width: 250px;
            height: 40px;
            margin-right: 10px;
            opacity: 0;
            transition: opacity 0.3s;
        }
    }
}
