@import '~scss/variables.scss';

$outline-color: #33353d;
$fill-color: #0d0d14;

.tooltip {
    position: relative;
    z-index: 10;

    &--light {
        .tooltip__message {
            color: rgba($color-base-dark-blue, 0.9);
            background: $color-white;
            border: 1px solid rgba($color-base-dark-blue, 0.12);
            border-radius: 2px;
            box-shadow: rgba($color-base-dark-blue, 0.2) 0 8px 16px;

            &:after,
            &:before {
                border-top-color: $color-white;
            }
        }
    }

    &__message {
        position: absolute;
        z-index: 0;
        display: inline-block;
        padding: 5px 10px;
        color: $color-white;
        font-size: 12px;
        font-family: $font-light;
        white-space: nowrap;
        text-align: center;
        background-color: $color-base-dark;

        border: 1px solid $outline-color;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba($color-black, 0.5);
        backface-visibility: hidden;
        cursor: default;
        opacity: 0;
        transition: opacity linear 0.25s;
        transition-delay: 0.25s;
        pointer-events: none;

        &:after,
        &:before {
            position: absolute;
            top: 100%;
            left: 50%;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            transform: translateX(-50%);
            content: '';
        }

        /* outline triangle */
        &:after {
            z-index: 1;
            border-color: $outline-color transparent transparent transparent;
            border-width: 7px;
        }

        /* triangle fill */
        &:before {
            z-index: 2;
            border-color: $fill-color transparent transparent transparent;
            border-width: 6px;
        }

        &.tooltip-override-left {
            margin-left: -5px;
            &:before,
            &:after {
                transform: unset;
            }

            &:before {
                left: 6px;
            }

            &:after {
                left: 5px;
            }
        }

        &.tooltip-override-right {
            margin-right: -5px;
            &:before,
            &:after {
                left: unset;
                transform: unset;
            }

            &:before {
                right: 6px;
            }

            &:after {
                right: 5px;
            }
        }
    }

    &__body {
        display: inline-block;
    }

    &--active {
        opacity: 1;
        transition-delay: 0s;
    }

    &__body {
        display: flex;
        align-items: center;
    }
}

.tooltip-top {
    bottom: 100%;
    left: 50%;
    margin-bottom: 10px;
    transform: translateX(-50%);
}

.tooltip-bottom {
    top: 100%;
    left: 50%;
    margin-top: 10px;
    transform: translateX(-50%);
}

.tooltip-left {
    top: 50%;
    right: 100%;
    margin-right: 10px;
    transform: translateY(-50%);

    /* outline triangle */
    &:after,
    &:before {
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
    }

    &:after {
        border-color: transparent transparent transparent $outline-color;
    }

    /* triangle fill */
    &:before {
        border-color: transparent transparent transparent $fill-color;
    }
}

.tooltip-right {
    top: 50%;
    left: 100%;
    margin-left: 10px;
    transform: translateY(-50%);
}

.tooltip-override-left {
    left: 0;
    transform: translateX(0);
}

.tooltip-override-right {
    right: 0;
    left: auto;
    transform: translateX(0);
}
