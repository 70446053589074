@import '~scss/variables.scss';

.badge {
    margin-right: 5px;
    padding: 2px 4px;
    color: $color-white;
    font-weight: 100;
    font-size: 0.6em;
    font-family: $font-light;
    text-transform: uppercase;
    background: $color-black;
    border-radius: 3px;
    opacity: 0.7;

    &--error {
        color: $text-color-white;
        background: $color-red;
        opacity: unset;
    }

    &:last-child {
        margin-right: unset;
    }
}
