@import '~scss/variables.scss';
$background-color-onahau-faded: rgba(#c7e9ff, 0.5);
$background-color-azure-radiance-faded: rgba(#009dff, 0.5);

.preview-schedule__plot {
    position: relative;

    background-color: $background-color-onahau-faded;
    border-radius: 8px;
    box-shadow: inset 0 4px 0 0 $background-color-azure-radiance-faded;

    .tooltip__message {
        border-radius: 8px;
    }

    &-inner {
        padding: 10px;
        cursor: pointer;
    }
}

.calendar-card-preview {
    position: absolute;
    top: -50px;
    left: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;
    padding: 30px;
    background-color: $color-white;
    border-radius: 8px;
    box-shadow: 0 8px 16px 8px rgba($color-base-dark-blue, 0.2);

    &__close {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }

    &__time {
        color: $text-color-midnight;
        font-weight: 300;
        font-size: 14px;
        font-family: Roboto-Light, serif;
        line-height: 16px;
        letter-spacing: 0;
    }

    &__thumbnail {
        width: 200px;
        height: 120px;
        margin: 15px 0;
        background: linear-gradient(180deg, rgba($background-color-swamp, 0) 0%, rgba($background-color-swamp, 0.71) 100%);
        border-radius: 2px;

        img {
            width: 200px;
            max-height: 120px;
        }
    }

    &__title {
        width: 300px;
        height: 48px;
        overflow: hidden;
        color: $text-color-midnight;
        font-size: 18px;
        font-family: Roboto-Regular, serif;
        line-height: 24px;
        letter-spacing: 0.2px;
        text-align: center;
        text-overflow: ellipsis;
    }

    &__description {
        width: 300px;
        color: $text-color-midnight;
        font-weight: 300;
        font-size: 12px;
        font-family: Roboto-Regular, serif;
        line-height: 16px;
        letter-spacing: 0.2px;
        text-align: center;
    }

    &__view {
        box-sizing: border-box;
        padding: 5px;
        color: rgba($text-color-midnight, 0.8);
        font-weight: bold;
        font-size: 13px;
        font-family: Roboto-Regular, serif;
        line-height: 15px;
        letter-spacing: 0.87px;
        text-align: center;
        background-color: $color-white;
        border: 1px solid $background-color-alto;
        border-radius: 3.5px;
    }
}
