@import '~scss/variables.scss';

// todo: review entire style sheet and make specific to only the modal
$header-height: 55px;
$timeline-controls-height: 40px;
$timeline-grid-height: 150px;
$timeline-height: $timeline-controls-height + $timeline-grid-height;
$shortcuts-height: 65px;

.shortcuts-modal {
    padding-right: 60px;
    padding-left: 60px;
    background: $color-base-dark;

    &__shortcuts {
        display: flex;
        flex-wrap: wrap;

        .shortcut-key {
            flex: 1 1 35%;
            margin: 10px 0;

            h4 {
                color: $color-white;
            }
        }
    }

    .editor__btn {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 30px;
        margin-right: 10px;
        font-family: $font-bold;
        background-color: $color-base-medium;
        border-radius: 5px;

        span,
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &-space span {
            transform: translate(-50%, -20%);
        }

        svg {
            width: 8px;
            fill: $color-disabled-text;
        }
    }

    &__shortcuts {
        display: flex;
        justify-content: space-between;
        font-weight: 100;
        font-size: 0.7em;
        font-family: $font-light;
        letter-spacing: 0.6px;

        h4 {
            margin: 0;
            font-weight: 100;
        }

        .hotkeys {
            flex: 0 0 auto;
            height: 30px;
            margin-right: 10px;
            padding: 0 10px;
            line-height: 26px;
            border: 2px solid $color-base-medium;
            border-radius: 5px;

            svg {
                position: relative;
                top: -2px;
                width: 20px;
                margin-right: 10px;
                fill: $color-disabled-text;
            }

            &:hover {
                border: 2px solid $color-base-light;
                cursor: pointer;
            }
        }

        .shortcut-keys {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
        }

        .shortcut-key {
            display: flex;
            flex-basis: 45%;
            align-items: center;
            color: $color-disabled-text;
            font-weight: 200;
        }

        .right-section {
            display: flex;

            button + button {
                margin-left: 15px;
            }

            .preview-btn,
            .revert-btn {
                height: 30px;
                padding: 0 20px;
                color: $color-disabled-text;
                font-family: $font-regular;
                line-height: 30px;
                background: $color-base-medium;
                border-radius: 5px;
                transition: color linear 0.25s, background-color linear 0.25s;

                &:hover {
                    background: $color-base-light;
                    cursor: pointer;
                }

                &:disabled {
                    opacity: 0.5;
                    &:hover {
                        color: $color-disabled-text;
                        background: $color-base-medium;
                        cursor: text;
                    }
                }
            }

            .blue {
                color: $color-white;
                background-color: $color-blue;
            }

            .revert-btn {
                $color: $color-white;
                width: 150px;

                svg {
                    width: 12px;
                    margin-right: 10px;
                    fill: $color-white;
                }

                h4 {
                    display: inline;
                }
            }
        }
    }
}
