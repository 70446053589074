$BUFFER_BACKGROUND_COLOR: rgb(235, 19, 21);

.buffer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__range {
        position: absolute;
        top: 0;
        height: 100%;
        background-color: rgba($BUFFER_BACKGROUND_COLOR, 0.25);
    }
}
