@import '../../../scss/variables';

.footer-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 25px;

    small {
        color: #848790;
        font-size: 12px;
        font-family: $font-light;
    }
}
