@import '~scss/variables.scss';

.page {
    width: auto;
    &__title {
        display: flex;
        gap: 16px;
        align-items: center;

        h1 {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 22px;
            font-family: $font-thin;
        }

        p {
            margin-top: 0;
            margin-bottom: 0;
            color: $color-base-light;
            font-size: 14px;
        }
    }

    &__cta-list {
        margin-top: 20px;
        padding-left: 0;
        list-style: none;
    }
}
