@import '~scss/variables.scss';

.thumbnail-list {
    display: flex;
    flex-flow: wrap;
    max-height: 570px;
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }
}

.thumbnail-item {
    position: relative;
    display: flex;

    flex-basis: calc(33.3% - (40px / 3));
    align-items: stretch;
    margin-right: 10px;
    margin-left: 10px;

    &:first-child,
    &:nth-child(3n + 1) {
        margin-right: 10px;
        margin-left: 0;
    }

    &:nth-child(3n) {
        margin-right: 0;
    }

    &:nth-child(n + 4) {
        margin-top: 20px;
    }

    &__empty {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;

        color: $text-color-santas-gray;
        font-size: 14px;
        font-family: $font-thin;

        background-color: $background-color-woodsmoke;
        border: 2px dashed $background-color-osiogray;
        border-radius: 2px;
    }

    &:hover {
        z-index: 10;
        cursor: pointer;
    }

    &__thumbnail {
        img {
            width: 100%;
        }
    }
}
