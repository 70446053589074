/* Colours */
$color-base-dark: rgb(13, 13, 20); // background black
$color-base-medium: rgb(35, 38, 44); // component dark grey
$color-base-light: rgb(57, 62, 70); // disabled component grey
$color-base-dark-blue: rgb(0, 27, 45);

$color-disabled-text: rgb(132, 135, 144);

$color-white: rgb(255, 255, 255);
$color-black: rgb(0, 0, 0);
$color-blue: #0281c7;
$color-red: rgb(235, 19, 21);
$color-yellow: #f5a623;

$color-tick-label: #a9adb7;
$color-tick-marker: #52545d;

$color-font-light: #52545d;

/* Fonts */
$font-thin: 'Roboto-Thin', arial;
$font-light: 'Roboto-Light', arial;
$font-regular: 'Roboto-Regular', arial;
$font-medium: 'Roboto-Medium', arial;
$font-bold: 'Roboto-Bold', arial;

/* Video Stats Panel */
$color-dark: rgb(0, 0, 0);

/* state colors */
$color-danger: #dc3545;
$color-warning: #ffc107;
$color-success: #28a745;
$color-info: #17a2b8;

/* text-colours */
/* Use hex-to-name -> http://chir.ag/projects/name-that-color */
$text-color-lochmara: #0281c7;
$text-color-mischka: #d0d3e2;
$text-color-white: #ffffff;
$text-color-santas-gray: #979aa9;
$text-color-midnight: #001b2d;
$text-color-zorba: #9d968e;

/* background-colours */
$background-color-woodsmoke: #15151c;
$background-color-brightgray: #393e46;
$background-color-buttercup: #f5a623;
$background-color-emerald: #2ed23e;
$background-color-lochmara: #0281c7;
$background-color-polar: #f7fbfd;
$background-color-osiogray: #848790;
$background-color-cinder: #0d0d14;
$background-color-lochmara-003: rgba($background-color-lochmara, 0.03); // content page background
$background-color-gallery: #eaeaea;
$background-color-alto: #d0d0d0;
$background-color-silver: #c4c4c4;
$background-color-dust-gray: #9b9b9b;
$background-color-swamp: #000d16;

/* transition timings */
$color-transition-timing: 0.2s;
$background-color-transition-timing: 0.2s;

$background-ad-marker-gradient: repeating-linear-gradient(320deg, #cce8f9, #cce8f9 7px, white 0px, white 11px);
$background-content-marker: #008ce4; ;
