@import '~scss/variables.scss';

.export-modal {
    max-width: 800px;
    padding-right: 60px;
    padding-left: 60px;

    &__step-description {
        display: flex;
        justify-content: center;
        p {
            max-width: 80%;
            text-align: center;
        }
    }

    &__inline-notification {
        margin-top: 20px;
    }

    p {
        margin-bottom: 0;
        color: $color-disabled-text;
        font-size: 14px;
    }

    form {
        margin-top: 20px;
    }

    /**
        save selection view
     */
    &__save-selection {
        display: flex;
        justify-content: space-between;
        .selection-card {
            flex-basis: 50%;
            padding-top: 30px;
            padding-bottom: 30px;
            &:first-child {
                padding-right: 60px;
                padding-left: 0;
                border-right: 1px solid $background-color-osiogray;
            }
            &:last-child {
                padding-right: 0;
                padding-left: 60px;
            }
        }
    }

    /**
        clip selection view
     */
    &__clip-selection {
        .clip-picker__single-row {
            max-height: 570px;
            overflow: hidden;
            overflow-y: auto;
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                width: 0; /* Remove scrollbar space */
                background: transparent; /* Optional: just make scrollbar invisible */
            }
        }
        .clip-picker {
            .card {
                min-height: 202px;

                .card-content {
                    flex-direction: column;
                    .card__description {
                        min-height: unset;
                        padding-bottom: unset;
                    }
                }
            }
        }
    }

    /**
        clip combiner
     */
    &__clip-combiner {
        .clip-picker {
            &__single-row {
                max-height: 570px;
                overflow: hidden;
                overflow-y: auto;
                scroll-behavior: smooth;

                &::-webkit-scrollbar {
                    width: 0; /* Remove scrollbar space */
                    background: transparent; /* Optional: just make scrollbar invisible */
                }
            }

            .card {
                .card-content .card__description {
                    min-height: unset;
                    padding-bottom: 10px;
                }
            }
        }

        .form-group + .form-group {
            margin-top: 20px;
        }

        .form-group.clip-title-field {
            label {
                margin-bottom: unset;
            }
        }
    }

    /**
        confirm clip create
     */
    &__confirm-clip-create {
        .clip-picker {
            margin-top: 20px;
        }

        .clip-picker__single-row {
            max-height: 570px;
            overflow: hidden;
            overflow-y: auto;
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                width: 0; /* Remove scrollbar space */
                background: transparent; /* Optional: just make scrollbar invisible */
            }
        }
        .clip-picker {
            .card {
                min-height: 202px;

                .card-content .card__description {
                    min-height: unset;
                }
            }
        }
    }

    /**
        confirm clip overwrite
     */
    &__confirm-clip-overwrite {
        .overwrite-comparison {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            &__separator {
                fill: $text-color-santas-gray;
            }

            .card {
                width: 280px;
                // overrides
                margin: unset;

                &__description {
                    // overrides
                    min-height: 100px !important;
                    padding-bottom: 15px !important;
                }
            }
        }
    }

    /**
        loading
     */
    &__loading {
        .modal__title {
            margin-top: 20px;
        }
        p {
            margin-top: 15px;
            margin-bottom: 0;
            color: $color-disabled-text;
            font-size: 14px;
        }
    }

    /**
        complete
     */
    &__complete {
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            margin: 0 auto;
            background-color: $color-blue;
            border-radius: 25px;

            &--warning {
                background-color: $color-yellow;
            }

            &--error {
                background-color: $color-red;
            }

            svg {
                fill: $color-white;
            }
        }

        .modal__title {
            margin-top: 20px;
        }

        p {
            margin-top: 15px;
            margin-bottom: 0;
            color: $color-disabled-text;
            font-size: 14px;
        }
    }
}

.selection-card {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    box-sizing: content-box;
    text-align: center;

    &--disabled {
        opacity: 0.5;
        filter: grayscale(1);
        pointer-events: none;
    }

    &--spacing {
        &:first-child {
            padding-right: 25px;
            padding-left: 0;
            border-right: 1px solid $background-color-osiogray;
        }

        &:last-child {
            padding-right: 0;
            padding-left: 25px;
        }
    }

    &__icon {
        fill: #848790;
    }

    &__content {
        margin-top: 20px;
        h3 {
            color: white;
            font-size: 18px;
            font-family: $font-light;
            line-height: 22px;
        }

        p {
            margin-top: 15px;
            margin-bottom: 0;
            color: $color-disabled-text;
            font-size: 14px;
        }

        &__warning {
            color: $color-warning;
            font-size: 12px;
        }
    }

    &__button {
        width: 100%;
        margin-top: 20px;
    }
}
