@import '~scss/variables.scss';

.dropdown-menu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;

    &--open {
        .dropdown-menu__dropdown {
            transform: translateY(5px);
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__trigger-button {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 16px;
        }
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        z-index: 1;
        width: max-content;
        margin: 0;
        padding: 0;
        color: $color-white;
        background: $background-color-brightgray;
        transform: translateY(-10px);
        opacity: 0;
        transition: transform linear 0.25s, opacity linear 0.25s;
        pointer-events: none;

        &--align-left {
            left: 0;
        }

        &--align-right {
            right: 0;
        }
    }

    .page--light & {
        $box-shadow-color: rgba($background-color-silver, 0.251);

        .dropdown-menu__dropdown {
            color: rgba($color-base-dark-blue, 0.9);
            background: $color-white;
            border: 1px solid rgba($color-base-dark-blue, 0.05);
            border-radius: 4px;
            box-shadow: 0px 0px 28px $box-shadow-color;
        }
    }
}
