@import '~scss/variables.scss';

$dropdown-width: 350px;

.custom-dropdown {
    position: relative;
    display: flex;
    align-items: center;
    width: $dropdown-width;
    height: 65px;
    background: $color-white;
    border: 1px solid rgba($background-color-alto, 0.5);
    border-radius: 4px;
    cursor: pointer;

    &--is-disabled {
        background: $background-color-gallery;
        cursor: unset;
        pointer-events: none;
    }

    &-options {
        position: absolute;
        z-index: 1001;
        max-height: 0;
        margin-top: 5px;
        overflow: hidden;
        background: $color-white;

        &--is-open {
            max-height: 300px;
            overflow: scroll;
        }
    }

    &__options-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__option {
        padding: 10px 10px 10px 30px;
        border-bottom: rgba($color-base-dark-blue, 0.1) 1px solid;
        cursor: pointer;
        transition: background 0.3s;
        transition: background, box-shadow 0.4s ease;

        &:hover,
        &--is-selected {
            box-shadow: inset $color-blue 2px 0 0;
        }

        &:hover {
            background: rgba($color-blue, 0.01);
        }

        &--is-selected {
            font-family: $font-bold;
            background: rgba($color-blue, 0.05);
        }
    }

    &__selected-value {
        display: flex;
        flex: 1;
        align-items: center;
        height: 100%;
        padding: 10px 10px 10px 20px;
    }

    &__dropdown-icon {
        position: absolute;
        right: 25px;
        transform: rotate(90deg);
        transition: transform 0.2s;
        fill: $background-color-silver;
    }

    &--is-open {
        svg {
            transform: rotate(-90deg);
        }
    }
}
