@import '~scss/variables.scss';
$color-blue-faded: rgba(0, 27, 45, 0.1);
$color-osiogray-faded: rgba($background-color-osiogray, 0.2);
$background-color-light: #f4f9fc;
$background-color-big-stone: #153143;

.calendar-preview {
    height: 100vh;
    overflow: scroll;

    &__header {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 5;
        display: grid;
        grid-template-columns: 1fr auto auto;
        align-items: center;
        height: 90px;
        padding: calc(10px + 1vh) calc(20px + 1vw);

        background: $color-white;
        border-bottom: 1px solid $color-blue-faded;
    }

    &__channel-details {
        position: relative;
        padding-right: 10px;
        color: rgba($color-base-dark-blue, 0.9);
        font-size: 1.3em;
        font-family: $font-regular;
        letter-spacing: 0.02em;

        img {
            margin-right: 10px;
            border-radius: 4px;
        }
    }

    &__body {
        position: relative;
        background: rgba($color-blue, 0.05);
    }

    &__action {
        display: flex;
        align-items: center;
        margin-right: 20px;
    }

    &__day-row {
        position: sticky;
        top: 90px;
        z-index: 4;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        width: 100%;
        padding-left: 100px;
        background: $color-white;
    }

    &__day {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;

        &.today .day-number {
            color: $color-white;
            font-weight: 500;
            font-family: $font-bold;
            background-color: rgba($color-base-dark-blue, 0.3);
            border-radius: 50%;
        }

        .day-name {
            width: 27px;
            height: 15px;
            color: rgba($color-base-dark-blue, 0.3);
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 0;
            text-align: center;
        }

        .day-number {
            width: 38px;
            height: 38px;
            color: #334957;
            font-weight: 300;
            font-size: 22px;
            font-family: $font-light;
            line-height: 38px;
            letter-spacing: 0;
            text-align: center;
        }
    }

    &__plots-wrapper {
        position: relative;
        z-index: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        width: 100%;
        padding-top: 100px;
        padding-left: 100px;

        &:before,
        &:after {
            position: absolute;
            z-index: 4;
            width: 100%;
            height: 100px;
            content: '';
            pointer-events: none;
        }

        &:after {
            bottom: 0;

            background: linear-gradient(
                to top,
                rgba($background-color-light, 0.7) 0%,
                rgba($background-color-light, 0.5) 60%,
                rgba($background-color-light, 0.2) 100%
            );
        }

        &:before {
            top: 0;
            background: linear-gradient(
                to bottom,
                rgba($background-color-light, 0.7) 0%,
                rgba($background-color-light, 0.5) 60%,
                rgba($background-color-light, 0.2) 100%
            );
        }
    }

    &__plot-column {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0 2px;
        background: rgba($color-white, 0.2) url('../../../../../assets/images/bg-stripe.png') repeat;

        &:not(:first-child) {
            border-left: 1px solid $color-osiogray-faded;
        }
    }

    &__plot-filler {
        position: relative;
        margin-bottom: 2px;
        text-align: center;

        .start-indicator {
            bottom: 0;
        }
    }

    &__plot {
        top: -100px;
        box-sizing: border-box;
        overflow: hidden;
        background: $color-white;
        border: 1px solid $color-blue-faded;
        border-radius: 2px;
        box-shadow: 0 8px 16px 8px $color-blue-faded;
    }

    &__plot-inner {
        padding: 10px;
    }

    &__time-column {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: calc(100% - 93px);
        margin-top: 100px;
        padding-top: 100px;
        pointer-events: none;
    }

    &__time {
        position: relative;
        width: 100%;
        height: 100px;
        border-top: 1px solid $background-color-alto;
        transform: translateX(93px);

        &__time_subdivision {
            position: absolute;
            top: 50%;
            width: 100%;
            border-top: 1px dashed $background-color-alto;
        }
    }

    &__time-text {
        color: $color-black;
        font-size: 15px;
        font-family: $font-bold;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
        transform: translate(-75px, -50%);
        opacity: 0.5;
    }

    .start-indicator {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        &__text {
            display: inline-block;
            padding: 5px;
            color: $color-white;
            font-size: 0.75em;
            background-color: $background-color-big-stone;
            border-radius: 2px;
        }

        &--upcoming {
            .start-indicator__text {
                color: $color-white;
                background: $color-blue;
            }

            .start-indicator__icon {
                background: $color-blue;
            }
        }

        &--live {
            border-top: 2px solid $color-red;

            .start-indicator__text {
                color: $color-white;
                background: $color-red;
            }

            .start-indicator__icon {
                background: $color-red;
            }
        }
    }

    .grouped-pod {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        color: rgba($color-base-dark-blue, 0.9);

        svg {
            width: 15px;
            height: 15px;
            margin-top: -2px;
            margin-right: 5px;
            fill: #87959f;
        }

        &__spacer {
            font-size: 12px;
        }

        &__duration {
            color: #87959f;
            font-weight: 500;
            font-size: 11px;
            font-family: $font-bold;
            line-height: 13px;
            letter-spacing: 0;
        }

        &__details {
            margin-left: 10px;
            color: #314858;
            font-weight: 500;
            font-size: 11px;
            font-family: $font-bold;
            line-height: 13px;
            letter-spacing: 0;
            text-align: right;
        }

        &__title {
            display: -webkit-box;
            margin-bottom: 10px;
            overflow: hidden;
            color: rgba($color-base-dark-blue, 0.9);
            font-size: 0.75em;
            line-height: 1.1em;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    .collapsed-pods {
        &__tabs {
            margin-bottom: 15px;
        }

        &__tab {
            display: inline-block;
            width: 100px;
            padding: 2px 10px;
            color: rgba($color-base-dark-blue, 0.5);
            font-size: 0.75em;
            white-space: nowrap;
            border: 1px solid $color-blue-faded;
            border-radius: 25px;
            cursor: pointer;

            &--is-selected {
                color: $color-blue;
                border-color: $color-blue;
            }
        }
    }
}
