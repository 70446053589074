@import '~scss/variables.scss';

.channel-manager {
    &__grid-line {
        position: absolute;
        top: 140px;
        width: 100%;
        height: 1px;
        background: rgba($background-color-alto, 0.72);
    }

    &__pods-scroller {
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }

        overflow-y: hidden !important; // override plugin styles
    }

    &__sticky-date {
        &-wrapper {
            position: absolute;
            left: 50%;

            z-index: 1;
            width: 200px;
            text-align: center;
            transform: translateX(-50%);
        }
        color: rgba($color-base-dark-blue, 0.72);
        font-size: 0.75em;

        font-family: $font-regular;
        line-height: 0.875em;
        letter-spacing: 0.09em;
        text-transform: uppercase;
    }

    &__pods-endpoint {
        writing-mode: vertical-rl;

        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding-top: 130px;
            font-size: 1em;
            font-family: $font-bold;
            letter-spacing: 0.02em;
            text-transform: uppercase;
        }
    }

    .scroller {
        &__control-buttons {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 600px;
            margin: 0 auto;
        }

        &--lg-button {
            width: 127px;
            height: 43px;
            color: rgba($color-base-dark-blue, 0.6);
            font-size: 0.75em;
            font-family: $font-bold;
            line-height: 14px;
            letter-spacing: 0.02em;
            background: $color-white;
            border: 1px solid rgba($background-color-alto, 0.6);
            border-radius: 4px;
        }

        &--sm-button {
            width: 127px;
            height: 62px;
            background: $color-white;
            border: 1px solid rgba($background-color-alto, 0.6);
            border-radius: 4px;
        }

        &--disabled-button {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

.move-to {
    &__dropdown {
        display: grid;
        grid-gap: 10px 20px;
        grid-template: 40px 1fr auto / 1fr 1fr;
        padding: 15px 20px;

        .toggle-switch-wrapper {
            grid-column: span 3;
        }
    }

    &__label {
        grid-column: span 2;
        font-size: 12px;
        font-family: $font-light;
        text-align: left;
        text-transform: uppercase;
    }

    &__input-group {
        display: grid;
        grid-template: repeat(2, auto) / repeat(3, auto);
    }

    &__input-wrapper {
        display: flex;
        flex-direction: column;

        label {
            font-size: 12px;
            font-family: $font-light;
            text-transform: uppercase;
        }

        input {
            width: 35px;
            height: 33px;
            font-size: 12px;
            text-align: center;
            border: 1px solid rgba(132, 135, 144, 0.2);
            border-radius: 2px;
            box-shadow: none;

            &.move-to__input-error {
                border: 1px solid $color-red;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                margin: 0;
                -webkit-appearance: none;
            }
        }
    }

    &__input-separator {
        margin-top: 30px;
        margin-right: 5px;
        margin-left: 5px;
    }

    &__error {
        position: absolute;
        top: 40px;
        left: 50%;
        color: $color-red;
        font-size: 12px;
        transform: translateX(-50%);
    }

    &__go-button {
        grid-column: span 2;
        justify-self: right;
        width: 50px;
        height: 31px;
        margin-left: 60px;
        font-size: 12px;
        font-family: $font-bold;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        border: 1px solid rgba(132, 135, 144, 0.2);
        border-radius: 4px;

        &:disabled {
            color: $text-color-mischka;
            background-color: rgba($background-color-gallery, 0.5);
            cursor: not-allowed;
        }
    }
}
