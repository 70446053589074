@import '~scss/variables.scss';

.preview-schedule__plot {
    position: relative;

    .tooltip__message {
        border-radius: 8px;
    }

    &-inner {
        cursor: pointer;
    }
}

.calendar-card-preview {
    position: absolute;
    top: -50px;
    left: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;
    padding: 30px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 8px 16px 8px rgba($color-base-dark-blue, 0.2);
    cursor: auto;

    &__close {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }

    &__time {
        color: $text-color-midnight;
        font-weight: 300;
        font-size: 14px;
        font-family: Roboto-Light, serif;
        line-height: 16px;
        letter-spacing: 0;
    }

    &__thumbnail {
        width: 200px;
        height: 120px;
        margin: 15px 0;
        background: linear-gradient(180deg, rgba($background-color-swamp, 0) 0%, rgba($background-color-swamp, 0.71) 100%);
        border-radius: 2px;

        img {
            width: 200px;
            max-height: 120px;
        }
    }

    &__title {
        width: 300px;
        height: 48px;
        overflow: hidden;
        color: #001b2d;
        font-size: 18px;
        font-family: Roboto-Regular, serif;
        line-height: 24px;
        letter-spacing: 0.2px;
        text-align: center;
        text-overflow: ellipsis;
    }

    &__description {
        width: 300px;
        color: #001b2d;
        font-weight: 300;
        font-size: 12px;
        font-family: Roboto-Regular, serif;
        line-height: 16px;
        letter-spacing: 0.2px;
        text-align: center;
    }

    &__bottom-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        height: 30px;
        margin: 10px 0;
        padding: 0 15px;
        color: rgba($color-base-dark-blue, 0.9);
        font-size: 0.65em;
        font-family: $font-bold;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        border-radius: 4px;

        svg {
            margin-left: 5px;
        }

        .tooltip__message {
            width: 220px;
            font-size: 1.2em;
            white-space: unset;
            text-transform: initial;

            & > div {
                padding-bottom: 10px;
                text-align: left;
            }
        }

        + .content-card__bottom-tag {
            margin-left: 10px;
        }
    }

    &__season-episode-icon {
        position: relative;
        margin-top: -16px;
        padding-left: 20px;

        &:after {
            top: -2px;
            left: -4px;
        }

        &:before {
            top: 3px;
            left: 0;
        }

        &:after,
        &:before {
            position: absolute;
            width: 15px;
            height: 15px;
            background: rgba($color-blue, 0.3);
            border-radius: 15px;
            content: '';
        }
    }

    &__view {
        box-sizing: border-box;
        padding: 5px;
        color: rgba($text-color-midnight, 0.8);
        font-weight: bold;
        font-size: 13px;
        font-family: Roboto-Regular, serif;
        line-height: 15px;
        letter-spacing: 0.87px;
        text-align: center;
        background-color: $color-white;
        border: 1px solid $background-color-alto;
        border-radius: 3.5px;
    }
}
