@import '~scss/variables.scss';

$border-color: $background-color-alto;
$button-border-color: rgba($background-color-osiogray, 0.2);
$arrow-color: rgba($background-color-osiogray, 0.8);
$small-screen-height: 1080px;
$medium-screen-width: 1260px;
$large-screen-width: 1440px;

.create-realm-modal {
    display: grid;
    grid-template-rows: 100px 400px;
    width: 840px;
    max-width: 840px;
    padding: 0;
    overflow-y: scroll;
    background: $color-white;
    border-radius: 6px;

    .loading-spinner {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-left: -50px;
        background-color: rgba($color-white, 0.7);
    }

    &__logo {
        width: 44px;
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        font-size: 1.5em;
        font-family: $font-regular;
        letter-spacing: 0.02em;

        img {
            margin-right: 10px;
            border-radius: 4px;
        }
    }

    &__body {
        position: relative;
        max-height: 80vh;
        padding: 0 50px;
        overflow-y: scroll;
        background-color: $background-color-lochmara-003;

        .form-field-group {
            margin: 10px;
            text-align: center;
        }

        .form-field-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;

            label {
                margin: 0 20px;
                color: rgba($color-base-dark-blue, 0.9);
                font-size: 1em;
                font-family: $font-bold;
                letter-spacing: 0.02em;
                text-transform: uppercase;
            }

            select {
                width: 50%;
            }
        }
    }

    &__action {
        width: 100%;
        text-align: right;
    }

    &__action-btn {
        width: 175px;
        height: 61px;
        margin: 40px 0;
        color: white;
        font-size: 15px;
        font-family: 'Roboto-Bold', arial;
        line-height: 18px;
        text-transform: uppercase;
        background: #0281c7;
        background: $color-blue;
        border-radius: 4px;

        &:disabled {
            background: rgba($color-base-dark-blue, 0.1);
        }
    }

    &__close-btn {
        position: absolute;
        top: 10px;
        right: 10px;

        svg {
            width: 35px;
            height: 35px;
            fill: $background-color-silver;
        }
    }

    &--form {
        .form-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        input {
            position: relative;
            display: flex;
            align-items: center;
            width: 350px;
            padding: 10px;
            background: white;
            border: 1px solid rgba(208, 208, 208, 0.5);
            border-radius: 4px;
            cursor: pointer;
        }

        &--error {
            color: $color-danger;
            text-align: center;
        }
    }
}
